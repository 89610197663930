import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  InputBase,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Grid
} from "@material-ui/core";

import useStyles from "./styles";
import { SORT_OPTIONS } from "../../../constants";
import { strings } from "../../../i18n";

export const SearchBar = ({
  history,
  setValue,
  value,
  setFilterValue,
  filterValue,
  placeholderText
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const hideMenu = (item) => {
    setAnchorEl(null);
    if (item && item.length > 0) setFilterValue(item);
  };
  const resetValue = () => {
    setValue("");
  };

  const renderMenuItems = () => {
    return SORT_OPTIONS.map((item, index) => (
      <div className={classes.menuItemCont} key={index}>
        <MenuItem onClick={() => hideMenu(item)} className={classes.menuItem}>
          <Typography>{strings(item)} </Typography>
          <Grid container align="center" justify="center" className={classes.menuItemIconCont}>
            {filterValue === item && (
              <Icon className={classes.menuItemIcon}>done</Icon>
            )}
          </Grid>
        </MenuItem>
      </div>
    ));
  };
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <Icon>search</Icon>
      </div>
      <InputBase
     placeholder={strings(placeholderText)}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        inputProps={{ "aria-label": "search" }}
      />
      {!value ? (
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          className={classes.filterIcon}
          onClick={openMenu}
        >
          <Icon>filter_list</Icon>
        </IconButton>
      ) : (
        <IconButton className={classes.filterIcon} onClick={resetValue}>
          <Icon>close</Icon>
        </IconButton>
      )}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={Boolean(anchorEl)}
        onClose={hideMenu}
      >
        {renderMenuItems()}
      </Menu>
    </div>
  );
};

export default SearchBar;
