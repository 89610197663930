 
import { TRANSACIONS_REF, _TRANSACTIONS_REF, _CUSTOMERS_REF } from "../../constants";
 


export default class CustomerStatementMiddleware {
    static getCustomerStatement(customerId, startDate, endDate) {
        return (dispatch, getState) => {
            return new Promise((resolve, reject) => {
         
                let customerTransactions = [];
  
                let query = "";

                if (startDate && endDate) {
                    query = _TRANSACTIONS_REF.where("customer_id", "==", customerId).where("type", "==", "default").where("transaction_timestamp", ">=", new Date(startDate)).where("transaction_timestamp", "<=", new Date(endDate)).orderBy("transaction_timestamp", "desc")
               }
                else {
                    query = _TRANSACTIONS_REF.where("customer_id", "==", customerId).where("type", "==", "default").orderBy("transaction_timestamp", "desc")
                }
                query.get().then(async transactions => {
                    let amount_of_total_credits = 0;
                    let amount_of_total_debits = 0;
                    let no_of_credits = 0;
                    let no_of_debits = 0;
                    // console.log("transactions : ", transactions)
                    for (let doc of transactions.docs) {
                        let docData = doc.data();

                        if (docData.sub_type === 'notification') {
                            continue;
                        }



                        customerTransactions.push({
                            ...docData,
                            id: doc.id,
                        });

                    }
                    for (let i = 0; i < customerTransactions.length; i++) {
                        if (customerTransactions[i].transaction_type === 'debit') {
                            amount_of_total_debits += Number(customerTransactions[i].amount);
                            no_of_debits++;
                        }
                        else {
                            amount_of_total_credits += Number(customerTransactions[i].amount);
                            no_of_credits++;
                        }
                    }
                    console.log(amount_of_total_credits, amount_of_total_debits)
                    let current_balance = amount_of_total_credits - amount_of_total_debits;

                    // console.log("userTransactions : ", userTransactions)
                    // dispatch(UserStatementAction.getUserTransaction(userTransactions))
                    // resolve(userTransactions)
                    resolve({ amount_of_total_credits, amount_of_total_debits, customerTransactions, no_of_debits, no_of_credits, current_balance })

                })
            })
        }

    }


}
