import { View } from "./layout";

const INITIAL_STATE = {
       loggedInUser: null,
       accounts: {
              accounts: [],
              allAccounts: [],
              filteredAccounts: [],
              filteredCustomersHavingOutstandings: [],
              searchedCustomersHavingOutstandings: [],
              lastAccountVisible: null
       },
       transactions: [],
       phoneContacts: {
              contacts: [],
              filteredContacts: [],
       },
       userStatement: {
              transactions: []
       },
       onboardingStates: {
              // pressedAddYourFirstCustomer: false,
              // pressedCreateNewCustomer: false,
              // pressedFirstCustomer: false,
              // pressedHelpButton: false,
              // pressedBackButton: false,
              // makeFirstTransaction: false,

              pressedAddYourFirstCustomer: true,
              pressedCreateNewCustomer: true,
              pressedFirstCustomer: true,
              pressedHelpButton: true,
              pressedBackButton: true,
              makeFirstTransaction: true
       },
       notifications: {
              customersForNotificationsToday: [],
              scheduledNotificationsCustomers: [],
              pendingNotificationsCustomers: []
       },
       ads: [],
       suggestiveTags: {
              suggestiveTagsDB: null,
              searchedSuggestiveTags: []
       },
       referrals: [],
       layout:{
              views: [View.Customers, View.CurrentTransaction , View.CurrentStatement],
              isDrawerOpen :false
       }
 
}

export default INITIAL_STATE;