import { makeStyles } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles((theme) => ({
  mainCont: {
    maxWidth: "500px",
    backgroundColor: "#fff",
    [`${theme.breakpoints.down("sm")} `]: {
      height: "100vh",
      maxWidth: "100%",
    },
 

  },
  calculatorCont: {
    [`${theme.breakpoints.down("sm")} `]: {
      position: "fixed",
      maxWidth: "100%",
    },
     
    bottom: "0px",
    width: "100%",
    maxWidth: "500px",
  },
  calculatorTextCont: {
    width: "80%",
    marginTop: 30,
  },
  calculatorText: {
    fontSize: 26,
    color: themeColors.amountDue,
    overflowWrap: "break-word",
    overflow: "auto",

  },
  inputHidden: {
    width:"0px",
    height:"0px",
    opacity:0
  },
  inputBottomBorder: {
    borderBottom: "2px solid",
    borderBottomColor: themeColors.amountInputBorder,
    width: "50%",
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "2px",
  },
  bold: {
    fontWeight: "bold",
    paddingLeft: "6px",
  },
  totalAmtText: {
    color: "#58595b",
    fontSize: 16,
  },
  datePickerMainCont: {
    display: "flex",
    alignItems: "center",
    marginTop: "30px",
    width: "50%",
    border: "1.5px solid",
    borderColor: themeColors.amountInputBorder,
    borderRadius: 8,
    cursor: "pointer",
  },
  datePickerCalenderIcon: {
    color: themeColors.calendarIcon,
    cursor: "pointer",
  },
  datePcikerDropDownICon: {
    color: themeColors.datePickerDropdown,
    cursor: "pointer",
  },
  noteMainCont: {
    paddingLeft: 0,
    position: "relative",
  },
  noteCont: {
    width: "calc( 100% - 70px)",
    height: "50px",
    border: "1px solid",
    paddingLeft: "15px",
    borderRadius: 30,
    justifyContent: "space-between",
    margin: "10px 5px",
    backgroundColor: themeColors.whiteColor,
    borderColor: themeColors.amountInputBorder,
  },
  noteTextInput: {
    color: "#000",
    fontSize: 16,
    width: "80%",
  },

  saveTransactionBtn: {
    height: "50px",
    width: "50px",
    display: "flex",
    borderRadius: 25,
    backgroundColor: themeColors.appColor,
    justifyContent: "center",
    alignItems: "center",
    marginRight: "5px",
    '&$disabled': {
      backgroundColor: themeColors.appColor,
    },
  },
  disabled: {


  },

  saveTransactionArrowIcon: {
    color: themeColors.whiteColor,
    fontSize: 25,
  },
  imgUploadLoader: {
    position: "absolute",
    right: "65px",
    top: 10,
  },
  imgTransaction: {

    width: 25,
    height: 25,
    objectFit: "contain"
  },
  cameraIconCont: {
    position: "absolute",
    right: "65px",
    top: 10,
  },
  cameraIcon: {
    color: themeColors.appColor,
    fontSize: 25,
    cursor: "pointer",
  },
  inputFile: {
    display: "none",
  },
}));

export default useStyles;
