import { makeStyles } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: "1rem",
    fontSize: "1rem",
    flex: 1,
  },
 
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: themeColors.appColor,
    width:"100%",
 
  },
  appBarShift: {
    marginLeft: themeStyleSheet.drawerWidth,
    width: `calc(100% - ${themeStyleSheet.drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
 
 
}));

export default useStyles;
