import { makeStyles } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles((theme) => ({
  mainCont: {
    [`${theme.breakpoints.down("sm")} `]: {
      height: "100vh",
      maxWidth: "100%",
      maxHeight: (props) =>
        props.isUpdate ? "100vh" : "-webkit-fill-available",
    },
    position: "fixed",
    height: "80%",
    maxWidth: "500px",
    backgroundColor: "#fff",
    padding: "0px",
    margin: "0px",
    maxWidth: "500px",
  },
  container: {
    padding: "20px 40px",
    overflow: "auto",

    [`${theme.breakpoints.down("sm")} `]: {
      maxHeight: "calc( 100% - 60px )",
    },
    maxHeight: "calc( 100% - 80px )",
  },
  avatarCont: {
    marginTop: "30px",
    position: "relative",
    ["@media (hover: none)"]: {
      "& $addImgBadge": {
        opacity: 1,
      },
    },
    "&:hover": {
      "& $addImgBadge": {
        opacity: 1,
      },
    },
  },
  inputFile: {
    display: "none",
  },
  customerAvatar: {
    height: "100px",
    width: "100px",
    backgroundColor: themeColors.imagePlaceholderBg,
  },
  addImgBadge: {
    backgroundColor: themeColors.appColor,
    color: "",
    position: "absolute",
    top: -5,
    zIndex: 2,
    right: -5,
    borderRadius: 20,
    width: 40,
    height: 40,

    opacity: 0,
    transition: ".3s ease",
  },
  addIcon: {
    fontSize: "25px",
    color: "#fff",
  },
  textFieldCont: {
    marginTop: "10px",
    width: "100%",
  },
  transactionAlertCont: {
    marginTop: "30px",
  },
  transactionIcon: {
    width: "35px",
    fontSize: 35,
    color: "#adadb2",
  },
  transactionAlertDesc: {
    marginTop: "30px",
  },
  transactionAlertDescText: {
    fontSize: 14,
    color: themeColors.datePickerText,
    width: `calc(100% - 35px )`,
    paddingLeft: "10px",
  },
  customerButton: {
    marginTop: "50px",
    padding: "8px 40px",
    borderRadius: "15px",
    backgroundColor: themeColors.appColor,
    "&:hover": {
      backgroundColor: "#353030",
    },
  },
  smsAlertLangCont: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  messageIcon: {
    color: themeColors.appColor,
    fontSize: "25px",
    width: "25px",
   
  },
  smsAlertLangText: {
    fontSize: "16px",
    width: `calc(70% - 25px )`,
    borderWidth: 0,
    margin:"0px",
    paddingLeft: "20px",
  },
  smsAlertSelectedLangText: {
    fontSize: "16px",
    borderWidth: 0,
    width:"30%",
    textAlign: "right",
  },
  deleteAccountBtn: {
    marginTop: "10px",
    padding: "8px 40px",
    borderRadius: "15px",
    backgroundColor: themeColors.amountDue,
    "&:hover": {
      backgroundColor: "#353030",
    },
  },
}));

export default useStyles;
