import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";

import { strings } from "../../../../i18n";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const DeleteDialog = ({ open, onClose, onAccept, onReject }) => {
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {strings("DELETE_CUSTOMER")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {strings("DO_YOU_WANT_TO_DELETE_THIS_CUSTOMER")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onReject} color="primary">
            Cancel
          </Button>
          <Button onClick={onAccept} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteDialog;
