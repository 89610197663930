import React, { useEffect, useState } from "react";
import {

  Button,
  Grid, TextField, Typography,

} from "@material-ui/core";

import useStyles from "./styles";
import Loader from "../../common/Loader";

import { useDispatch, useSelector } from "react-redux";

import DrawerHeader from "../../common/DrawerHeader";
import TagManager from 'react-gtm-module'
import LayoutActions from "../../../store/actions/Layout";
import DrawerLayout from "../../common/DrawerLayout";
import { strings } from "../../../i18n";
import UserMiddleware from "../../../store/middleware/User";
import { getLoggedInUser } from "../../../store/selectors";


export const CreateProfile = ({ onModalClose, account, history }) => {
  const isNewAccount = history?.location?.state?.isNewAccount
  const classes = useStyles({ isUpdate: account?.name || false });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState();
  const user = useSelector(getLoggedInUser);

  useEffect(() => {
    let values = {
      name: user.name,
      business_name: user.business_name
    }

    setValues(values)
  }, [])
  const handleChange = (event) => {
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  }
  const handleSubmit = () => {
    if (values?.name?.length === 0) {

      setErrors((errors) => ({
        ...errors,
        name: strings("FIELD_REQUIRED")
      }));
      return;
    } else if (values?.name?.match(/[!@#$%^&*(),.?":{}|<>]/g)) {
      setErrors((errors) => ({
        ...errors,
        name: "Only alphabets are allowed"
      }));
      return;
    } else if (values.business_name.match(/[!@#$%^&*(),.?":{}|<>]/g)) {
      setErrors((errors) => ({
        ...errors,
        business_name: "Only alphabets are allowed"
      }));

      return;
    }

    let updatedData = {
      business_name: values.business_name,
      is_active: true,
      from_new_app: false,
      name: values.name,
      user_last_activity: new Date(),
      contextID: 'fake',
    }

    setLoading(true)
    try {
      dispatch(UserMiddleware.update(updatedData)).then(() => {
        setLoading(false)
        history.push("/dashboard")
      }).catch((err) => {
        setLoading(false)
        console.log("User Updation Err", err)
      })

    const tagManagerArgs = {
      gtmId: 'GTM-PC8XB9L',
      dataLayer: {
        "event":  isNewAccount ?  "signup_successfull" :"update_profile",
        user_name : updatedData.name , 
        user_id : user.id , 
        business_name :updatedData.business_name,  
        page_title: isNewAccount ? "CreateProfile":"UpdateProfile"
      }
    }
    TagManager.dataLayer(tagManagerArgs)

    } catch (err) {

      console.log("USER UPDATED ERROR !!!", err)
      setLoading(false)

    }
  }
  return (
    <>
      <Loader loading={loading} />
      <DrawerLayout>
        <Grid
          direction="row"
          justify="center"

          container
          xs={12}
          className={classes.mainCont}
        >
          <DrawerHeader
            title={isNewAccount ? strings("SIGN_UP") : strings("UPDATE")}
            leftIcon={"arrow_backward"}
            leftIconFunc={() => { isNewAccount ? dispatch(UserMiddleware.logout()) : history.goBack() }}
          />

          <Grid


            xs={12}

            className={classes.container}
          >
            <Typography className={classes.title}>{isNewAccount ? strings("ENTER_YOUR_DETAILS_TO_GET_STARTED") : strings("PERSONAL_INFORMATION")}</Typography>
            <div className={classes.textFieldCont}>

              <TextField
                fullWidth
                label={`${strings("NAME")}`}
                name="name"
                onChange={handleChange}
                value={values.name || ""}
                error={errors?.name}
                helperText={errors?.name}
              />
            </div>
            <div className={classes.textFieldCont}>

              <TextField
                fullWidth
                label={`${strings("BUSINESS_NAME")}`}
                name="business_name"
                onChange={handleChange}
                value={values.business_name || ""}
                error={errors?.business_name}
                helperText={errors?.business_name}
              />
            </div>
            <Grid container item direction="column">
              <Button
                variant="contained"
                color="primary"
                className={classes.customerButton}
                onClick={handleSubmit}
              >
                {isNewAccount ? strings("GET_STARTED") : strings('UPDATE')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DrawerLayout>

    </>
  );
};

export default CreateProfile;
