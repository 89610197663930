import UserAction from "../actions/User";
import { firebase, auth, firestore } from "../../constants";
import {
  USERS_REF,
  _USERS_REF,
  ACCOUNTS_REF,
  TRANSACIONS_REF,
} from "../../constants";
import {
  loginWithCustomToken,
  resendVerificationCode,
  getTokenFromUUID,
} from "../api/User";
import { NetInfo } from "../../utils";
import AccountsMiddleware from "./Accounts";

let unsubscribe = null;

export default class UserMiddleware {
  static login(user) {
    return (dispatch) => {
      console.log("In login user action");
      dispatch(UserAction.loginUser(user));
    };
  }

  
 
  static loginWithCustomToken(mobile_no, signature) {
    return new Promise((resolve, reject) => {
      loginWithCustomToken(mobile_no, signature)
        .then(function (response) {
          console.log("response.data", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error, "error");
          reject();
        });
    });
  }

  static resendVerificationCode(mobile_no) {
    return new Promise((resolve, reject) => {
      resendVerificationCode(mobile_no)
        .then(function (response) {
          console.log("response.data", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error, "error");
          reject();
        });
    });
  }

  static verifyLogin(uid) {
    return new Promise((resolve, reject) => {
      getTokenFromUUID(uid)
        .then(function (response) {
          console.log("response.data", response.data);

          resolve(response.data.customToken);
        })
        .catch(function (error) {
          console.log(error, "error");
          reject();
        });
    });
  }
  static unsubscribeQueryListener() {
    if (unsubscribe) {
      console.log("unsubscribeQueryListener of user.......")
      try {
        unsubscribe()
      } catch (err) {
        console.log("err : ", err)
      }
    }
  }
  static getUser(userId) {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        console.log("getting user from firestore !!!");
        if (unsubscribe) {
          try {
            unsubscribe();
          } catch (err) {
            console.log("err : ", err);
          }
        }
        let query = _USERS_REF.where("id", "==", userId);
        unsubscribe = query.onSnapshot(
          { includeMetadataChanges: true },
          function (querySnapshot) {
            if (!querySnapshot.metadata.fromCache) {

              let user = querySnapshot?.docs[0]?.data();
              if (user) {
                user = { ...user, isAuthenticated: true }
                console.log("Login User")
                dispatch(UserAction.loginUser(user));

              }else{
                dispatch(UserAction.logoutUser());

              }
              resolve();
            } else {
              resolve();
            }
          }
        );
      });
    };
  }
  static logout() {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        console.log("In logout user action");
        auth
          .signOut()
          .then((res) => {

            dispatch(UserAction.logoutUser());
            this.unsubscribeQueryListener();
            AccountsMiddleware.unsubscribeQueryListener();

            console.log("Logout from firebase successfully !!!!");
            resolve(res);
          })
          .catch((err) => {
            console.log("Logout from firebase error : ", err);
            reject(err);
          });
      });
    };
  }

  static update(updatedData) {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        console.log("update user middleware")
        let { user } = getState();

        console.log("user  : ", user)
        var docRef = _USERS_REF.doc(user.id);

        updatedData = {
          ...updatedData,
          user_last_activity: new Date(),
          contextID: 'fake',
          from_new_app: false
        }
        setTimeout(() => {

          docRef.update(updatedData)
          // this.updateUserInRedux(updatedData);
          let data = {
            ...user,
            ...updatedData
          }
          dispatch(UserAction.updateUser(data))
          resolve()
        })


      })

    }
  }
}
