import Transaction from "../actions/Transaction";

import moment from "moment";
import firebase from "firebase/app";
import {
  TRANSACIONS_REF,
  ACCOUNTS_REF,
  _TRANSACTIONS_REF,
  _CUSTOMERS_REF,
} from "../../constants";
import NetInfo from "../../utils";

var unsubscribe = null;

export default class TransactionsMiddleware {
  static getTransactions(accountId) {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        if (unsubscribe) {
          console.log("Unsubscribing Transactions Listener!!!!");
          try {
            unsubscribe();
          } catch (err) {
            console.log("err : ", err);
          }
        }

        unsubscribe = _TRANSACTIONS_REF
          .where("customer_id", "==", accountId)
          .where("type", "==", "default")
          .orderBy("transaction_timestamp", "desc")

          .onSnapshot(
            { includeMetadataChanges: true },
            function (querySnapshot) {
            //  console.log("Transaction querySnapshot : ", querySnapshot);
              let transactions = [];
              let dateTag = "";
              for (let doc of querySnapshot.docs) {
                let docData = doc.data();

                if (
                  querySnapshot.size === 1 &&
                  docData.sub_type === "notification"
                ) {
                  break;
                }

                let timestamp = new Date(docData?.transaction_timestamp);
                dateTag = moment(timestamp.getTime()).calendar(null, {
                  sameDay: "[Today]",
                  nextDay: "[Tomorrow]",
                  nextWeek: "dddd",
                  lastDay: "[Yesterday]",
                  // lastWeek: '[Last] dddd',
                  lastWeek: "D MMMM YYYY",
                  sameElse: "D MMMM YYYY",
                });
                transactions.push({
                  ...docData,
                  id: doc.id,
                  is_synced: !doc.metadata.hasPendingWrites,
                  dateTag,
                });
              }

              dispatch(Transaction.getTransactions(transactions));
              resolve(transactions);
            }
          );
      });
    };
  }
  static createTransaction(data) {

    data = {
      ...data,
      from_new_app: false,
    }
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        console.log("data.account_id : ", data.customer_id);
        try {
          _TRANSACTIONS_REF
            .add(data)
            .then((res) => {
              console.log("Transaction has been created successfully !!!");
              resolve(res);
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        } catch (e) {
          console.log(e);
          reject(e);
        }
      });
    };
  }

  static update(transactionId, updatedData) {
    updatedData = {
      ...updatedData,
      from_new_app: false
    }
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        var docRef = _TRANSACTIONS_REF.doc(transactionId);

        docRef
          .update(updatedData)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    };
  }
}
