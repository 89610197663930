import React, { useEffect, useState } from "react";
import {
  Switch,
  Button,
  Grid,
  TextField,
  Typography,
  Icon,
  Avatar,
  IconButton,
  CircularProgress,
  ButtonBase,
} from "@material-ui/core";
import UserMiddleware from "../../../store/middleware/User";
import useStyles from "./styles";
import Loader from "../../common/Loader";
import { strings } from "../../../i18n";
import { customer } from "../../../firebase/schema/customers";
import { transaction } from "../../../firebase/schema/transactions";
import validate from "./Validation";
import useForm from "../../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedInUser } from "../../../store/selectors";
import { v4 as uuidv4 } from "uuid";
import AccountsMiddleware from "../../../store/middleware/Accounts";
import TransactionsMiddleware from "../../../store/middleware/Transactions";
import AccountHeader from "../../common/AccountHeader";
import { storeImageInFirebase, getLang } from "../../../utils";
import DrawerHeader from "../../common/DrawerHeader";
import DeleteDialog from "./Dialogs/DeleteDialog";
import LayoutActions from "../../../store/actions/Layout";
import SMSLangugaeSelection from "../SMSLangugaeSelection";
import TagManager from 'react-gtm-module'
export const AddAccount = ({ onModalClose, account }) => {
  const classes = useStyles({ isUpdate: account?.name || false });
  const [loading, setLoading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [imgPreviewUrl, setImagePreviewUrl] = useState(false);
  const [imgPath, setImgPath] = useState("");
  const [imgUploading, setImgUploading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(getLoggedInUser);
  const [showLanguageSelection, setShowLanguageSelection] = useState(false);
  const [selectedLang, setSelectedLang] = useState();
  
  const onSubmit = () => {
 
    if (account?.name) updateAccount();
    else addAccount();
  };

  const { values, errors, handleChange, handleSubmit, setValues } = useForm(
    onSubmit,
    validate
  );
  const onImageChange = (e) => {
    setImgUploading(true);
    const file = e.target.files[0];
    if (!file) return;
    setUploading(true);
    let reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);

    if (!file) return;

    storeImageInFirebase("_customers", new Date().getTime().toString(), file)
      .then((url) => {
        setImgPath(url);
        setImgUploading(false);
      })
      .catch((err) => {
        setImgUploading(false);
      });
  };
  const deleteAccount = () => {
    setLoading(true);
    let data = {
      type: "deleted",
    };
    let customerData = account;
    let userUpdatedData = {
      no_of_customers: user.no_of_customers - 1,
      current_balance: user.current_balance - customerData.current_balance,
      no_of_credits: user.no_of_credits - customerData.no_of_credits,
      no_of_debits: user.no_of_debits - customerData.no_of_debits,
      amount_of_credits:
        user.amount_of_credits - customerData.amount_of_credits,
      amount_of_debits: user.amount_of_debits - customerData.amount_of_debits,
      no_of_transactions:
        user.no_of_transactions - customerData.no_of_transactions,
      amount_of_total_transactions:
        user.amount_of_total_transactions -
        customerData.amount_of_total_transactions,
    };
    let accountId = account.id;
    try {
      dispatch(AccountsMiddleware.update(accountId, data)).then(() => {
        setLoading(false);
        dispatch(LayoutActions.hideCurrentTransaction());
        onModalClose();
      });
    } catch (e) {
     // console.log(e);
    }
  };
  useEffect(() => {
 
    if (account) {
      setValues({
        name: account?.name,
        phone: account?.mobile_no,
        businessName: account?.business_name,
        creditLimit: account?.credit_limit,
        is_send_transactional_sms_active:
          account?.is_send_transactional_sms_active || false,
      });

      setSelectedLang(account?.preferred_language || "roman_ur");
      setImgPath(account.img_url);
    }else{
      setValues({
        is_send_transactional_sms_active:true,
      });
      setSelectedLang(  "roman_ur");
    }
  }, []);

  const updateAccount = async (e) => {
    let {
      name,
      phone,
      businessName,
      creditLimit,
      is_send_transactional_sms_active,
    } = values;

    setLoading(true);
    let data = {
      name,
      is_send_transactional_sms_active:
        is_send_transactional_sms_active || false,
      nameLowerCase: name.toLowerCase(),
      mobile_no: phone || null,
      business_name: businessName || null,
      credit_limit: creditLimit || null,
      img_url: imgPath,
      preferred_language: selectedLang,
    };


    dispatch(AccountsMiddleware.update(account.id, data)).then(() => {
      onModalClose();
    });
  };

  const addAccount = async () => {
   // console.log("Add account Called");
    let {
      name,
      phone,
      businessName,
      creditLimit,
      is_send_transactional_sms_active,
    } = values;

    setLoading(true);
    let data = {
      ...customer,
      user_id: user.id,
      name,
      is_send_transactional_sms_active:
        is_send_transactional_sms_active || false,
      nameLowerCase: name.toLowerCase(),
      mobile_no: phone || null,
      business_name: businessName || null,
      credit_limit: creditLimit || null,
      creation_timestamp: new Date(),
      last_activity: new Date(),
      img_url: imgPath,
      id: uuidv4(),
      preferred_language: selectedLang,
    };
    //console.log("customer data that is going to add in firebase..... ", data);

    try {
      const res = dispatch(AccountsMiddleware.addAccount(data));
      let notificationTransactionData = {
        ...transaction,
        note: "Your data and transactions are completely secure",
        creation_timestamp: new Date(),
        transaction_timestamp: new Date(),
        user_id: user.id,
        customer_id: data.id,
        customer_name: name,
        sub_type: "notification",
      };
      dispatch(
        TransactionsMiddleware.createTransaction(notificationTransactionData)
      ).then((res) => {
        const tagManagerArgs = {
          gtmId: 'GTM-PC8XB9L',
          dataLayer: {
            "event": "add_customer_success",
            phone :phone,
            credit_limit: creditLimit ||0,
            customer_business_name: businessName || '',
            alert_status  :is_send_transactional_sms_active ?"on" :"off",
            customer_name:name.toLowerCase(),
            sms_language:selectedLang
          } 
        }
    
        TagManager.dataLayer(tagManagerArgs)
        onModalClose();
      });
    } catch (err) {
     // console.log("err : ", err);
    }
    setLoading(false);
  };

  const onLanguageChange = (value) => {
    setSelectedLang(value);
    setShowLanguageSelection(false);
  };

  if (showLanguageSelection)
    return (
      <SMSLangugaeSelection
        onClose={() => setShowLanguageSelection(false)}
        preferred_language={selectedLang}
        onSubmit={onLanguageChange}
        account = {account}
      />
    );
  return (
    <>
      <Loader loading={loading} />

      <Grid
        direction="row"
        justify="center"
        container
        xs={12}
        className={classes.mainCont}
      >
        <DrawerHeader
          title={
            account?.name ? strings("UPDATE_CUSTOMER") : strings("NEW_CUSTOMER")
          }
          leftIcon={"arrow_backward"}
          leftIconFunc={onModalClose}
        />

        <Grid
          direction="row"
          container
          xs={12}
          alignItems="center"
          justify="center"
          className={classes.container}
        >
          <input
            accept="image/*"
            className={classes.inputFile}
            id="contained-button-file"
            type="file"
            onChange={onImageChange}
          />
          <label htmlFor="contained-button-file">
            <IconButton className={classes.avatarCont} component="span">
              <Grid
                container
                alignItems="center"
                justify="center"
                className={classes.addImgBadge}
              >
                <Icon className={classes.addIcon}>
                  {imgPath ? "edit" : "add"}
                </Icon>
              </Grid>

              <Avatar className={classes.customerAvatar} src={imgPath}>
                {imgUploading ? (
                  <CircularProgress size={20} />
                ) : (
                  !imgPath && (
                    <Icon className={classes.transactionIcon}>camera_alt</Icon>
                  )
                )}
              </Avatar>
            </IconButton>
          </label>
          <div className={classes.textFieldCont}>
            <TextField
              fullWidth
              label={`${strings("NAME")}*`}
              name="name"
              onChange={handleChange}
              value={values.name || ""}
              error={errors?.name}
              helperText={errors?.name}
            />
          </div>
          <div className={classes.textFieldCont}>
            <TextField
              fullWidth
              label={`${strings("PHONE_NUMBER")}*`}
              name="phone"
              onChange={handleChange}
              value={values.phone || ""}
              error={errors?.phone}
              helperText={errors?.phone}
            />
          </div>
          <div className={classes.textFieldCont}>
            <TextField
              fullWidth
              label={strings("CREDIT_LIMIT")}
              name="creditLimit"
              onChange={handleChange}
              value={values.creditLimit || ""}
              error={errors?.creditLimit}
              helperText={errors?.creditLimit}
            />
          </div>
          <div className={classes.textFieldCont}>
            <TextField
              fullWidth
              label={strings("BUSINESS_NAME")}
              name="businessName"
              onChange={handleChange}
              value={values.businessName || ""}
              error={errors?.businessName}
              helperText={errors?.businessName}
            />
          </div>
          <Grid
            container
            item
            alignItems="center"
            justify="space-between"
            className={classes.transactionAlertCont}
          >
            <Typography>{strings("TRANSACTION_ALERTS")}</Typography>
            <Switch
              name={"is_send_transactional_sms_active"}
              checked={values?.is_send_transactional_sms_active || false}
              onChange={handleChange}
            />
          </Grid>

          <Grid
            container
            item
            alignItems="center"
            direction="row"
            className={classes.transactionAlertDesc}
          >
            <Icon className={classes.transactionIcon}>info</Icon>
            <Typography className={classes.transactionAlertDescText}>
              {strings("TRANSACTION_TOGGLE_MESSAGE")}
            </Typography>
          </Grid>

          <ButtonBase
            container
            item
            direction="row"
            className={classes.smsAlertLangCont}
            onClick={() => setShowLanguageSelection(true)}
          >
            <Icon className={classes.messageIcon}>message</Icon>
            <Typography align="left" className={classes.smsAlertLangText}>
              {strings("SMS_ALERT_LANGUAGE")}
            </Typography>

            <Typography className={classes.smsAlertSelectedLangText}>
              {getLang(selectedLang)}
            </Typography>
          </ButtonBase>

<Grid container item direction="column">           <Button
            variant="contained"
            color="primary"
            className={classes.customerButton}
            onClick={handleSubmit}
            disabled={imgUploading}
          >
            {account?.name
              ? strings("UPDATE_CUSTOMER")
              : strings("NEW_CUSTOMER")}
          </Button>

          {account?.name && (
            <Button
              variant="contained"
              color="primary"
              className={classes.deleteAccountBtn}
              onClick={() => setShowDeleteDialog(true)}
              disabled={imgUploading}
            >
              {strings("DELETE_CUSTOMER")}
            </Button>
          )}
          </Grid>
        </Grid>
      </Grid>

      <DeleteDialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        onAccept={deleteAccount}
        onReject={() => setShowDeleteDialog(false)}
      />
    </>
  );
};

export default AddAccount;
