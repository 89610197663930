import { useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "./styles";

export const Counter = ({ count, setCount, resendCode }) => {
  const styles = useStyles({ count });

  useEffect(() => {
    const timer = count > 0 && setInterval(() => setCount(count - 1), 1000);
    return () => clearInterval(timer);
  }, [count]);

  const resendOTP = () => {
    if (count > 0) return;
    resendCode();
  };
  return (
    <>
      <Grid container alignItems="center" justify="center" direction="row">
        <Typography>Didn't get code ? </Typography>
        <Typography onClick={resendOTP} className={styles.resendCodeText}>
          {count > 0 ? "Resend Code in " + count + " secs" : "Resend Code"}
        </Typography>
      </Grid>
    </>
  );
};

export default Counter;
