import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
 
import {
  Grid, 
  Icon,
  Typography,
  ButtonBase,
 
} from "@material-ui/core";

import useStyles from "./styles";
import { strings } from "../../../i18n";
 
import { formatNum, formattedTime } from "../../../utils";

export const DebitMessage = ({ item ,onClick}) => {
  const classes = useStyles({type:item.transaction_type});
 
  return (
    <>
 
      <Grid
        container
        item
        justify={item.transaction_type === "debit" ? "flex-end" : "flex-start"}
        style ={{height :"fit-content"}}
      >
        <ButtonBase className={classes.transactionCont} onClick ={onClick}>
          <Grid container item justify="flex-start">
            <Typography className={classes.transactionAmountText}>
              Rs {formatNum(item.amount)}
            </Typography>

            <Grid
              container
              item
              alignItems="center"
              justify="space-between"
              className={classes.transactionNoteCont}
            >
              <Typography align="left" className={classes.transactionNoteText}>
                {item.note || strings("NOTE_NOT_SPECIFIED")}
              </Typography>
              {!item.transaction_image && (
                <div className={classes.transactionTimeStampCont}>
                  <Typography className={classes.transactionTimeStamp}>
                    {formattedTime(item.creation_timestamp)}
                  </Typography>
                  <Icon className={classes.cloudIcon}>cloud</Icon>
                </div>
              )}
            </Grid>

            {item.transaction_image && (
              <Grid
                container
                item
                direction="row"
                justify="flex-end"
                alignItems="flex-end"
              >
                <img
                  src={item.transaction_image}
                  className={classes.transaction_image}
                />
                <div className={classes.transactionTimeStampCont}>
                  <Typography className={classes.transactionTimeStamp}>
                    {formattedTime(item.creation_timestamp)}
                  </Typography>
                  <Icon className={classes.cloudIcon}>cloud</Icon>
                </div>
              </Grid>
            )}
          </Grid>
        </ButtonBase>
      </Grid>
    </>
  );
};

export default DebitMessage;
