 export default {
    LOGIN_VERIFICATION_CODE_TEXT: "A verification code is sent on ",
    LOGIN_COUNTRY_TEXT: "Pakistan (+92)",
    LOGIN_PHONENUMBER: "Phone Number",
    WELCOME: "Welcome",
    LOGIN: "Login",
    CHANGE_LANGUAGE: "Change Language",
    SELECT_LANGUAGE: "Select Language",
    ADD_ACCOUNT: "Add Account",
    ADD_CUSTOMER: "Add Customer",
    PAY: "Pay",
    DUE: "Due",
    CLEAR: "Clear",
    PAID: "Paid",
    ADDED_ON: "Added on",
    TOOK_LOAN: "Took Loan of",
    RUPEES: "Rs",
    SEARCH_CONTACTS: "Search Account",
    SEARCH_CUSTOMERS: "Search Customer",
    SALAM: "Salam",
    NEW_CONTACT: "New Account",
    NEW_CUSTOMER: "New Customer",
    GIVE_PAYMENT: "You gave",
    RECEIVE_PAYMENT: "You got",
    CHOOSE_YOUR_LANGUAGE: "Choose your language",
    NEXT: "Next",
    BACK: "Back",
    VERIFICATION_CODE: "Verification Code",
    A_verification_code_will_be_sent_on_your_number_via_SMS_Service_charges_may_apply: "A verification code sent via SMS on this number",
    VERIFICATION_CODE_MSG: "A verification code sent via SMS on this number",
    DIDNT_GET_CODE: "Didn't recieve the code?",
    RESEND_CODE: "Resend Code",
    ENTER_MOBILE_NUMBER: "Enter Mobile number",
    PAKISTAN_92: "Pakistan (+92)",
    PERSONAL_INFORMATION: "Personal Information",
    GET_STARTED: "Get Started",
    LOGOUT: "Logout",
    HOME: "Home",
    ADD: "ADD",
    NO_ACCOUNT_FOUND_NAMED: "No Account found named",
    NO_CUSTOMER_FOUND_NAMED: "No Customer found named",
    CONTACTS_FROM_PHONEBOOK: "Contacts from phonebook",
    SETTINGS: "SETTINGS",
    ABOUT_US: "About Us",
    PRIVACY_POLICY: "Privacy Policy",
    HELP: "Help",
    CONTACT: "Contact",
    SORT_NAME: "Name",
    SORT_AMOUNT: "Amount",
    SORT_LATEST: "Latest",
    SORT_PAYMENT_DUE: "Payment Due",
    NAME: "Name",
    PHONE_NUMBER: "Phone Number",
    BUSINESS_NAME: "Business Name",
    TODAY: "Today",
    YESTERDAY: "Yesterday",
    ENTER_AMOUNT: "Enter Amount",
    WRITE_NOTE_HERE: "Write Note Here",
    FULL_NAME: "Full Name",
    EMAIL_ADDRESS: "Email Address",
    BUSINESS_TYPE: "Business Type",
    TRANSACTION_INFO: "Transaction Info",
    SYNC_SUCESSFULLY: "Sync Successful",
    NOT_SYNC: "Not Sync",
    SHARE_TRANSACTION: "Share Transaction",
    DELETE_TRANSACTION: "Delete Transaction",
    EDIT_TRANSACTION: "Edit Transaction",
    NOTE_NOT_SPECIFIED: "Note not specified",
    ADVANCE: 'Advance',
    ACCOUNT: 'Account',
    WHATSAPP: 'Whatsapp',
    SHARE: 'Share',
    VERSION: 'Version',
    UPDATE: 'Update',
    UPDATE_ACCOUNT: "Update Account",
    UPDATE_CUSTOMER: "Update Customer",
    ACCOUNTS: 'Accounts',
    USER_STATEMENT: "User Statement",
    PROFILE: "Profile",
    LANGUAGE: 'Language',
    BALANCE: 'Balance',
    NOTE: 'Note',
    DELETE_ACCOUNT: "Delete Account",
    DELETE_CUSTOMER: "Delete Customer",
    NET_BALANCE: "Net Balance",
    CREDITS: "You Got",
    PAYMENTS: "Payments",
    DEBITS: "You Gave",
    DO_YOU_WANT_TO_DELETE_THIS_TRANSACTION: "Do you want to delete this transaction?",
    TRANSACTION_HAS_BEEN_DELETED: "Transaction has been deleted",
    RESTORE_TRANSACTION: "Restore Transaction",
    DO_YOU_WANT_TO_RESTORE_THIS_TRANSACTION: "Do you want to restore this transaction?",
    TRANSACTION_HAS_BEEN_RESTORED: "Transaction has been restored",
    TRANSACTION_MESSAGE_WHEN_GIVING: "",
    TRANSACTION_MESSAGE_WHEN_TAKING: "",
    CONTACT_US_MESSAGE: "",
    VERIFIED_BY_CREDIT_BOOK: "Verified By CreditBook",
    AMOUNT_GIVEN: "Amount Given",
    AMOUNT_RECEIVED: "Amount Received",
    PLEASE_ENTER_AMOUNT: "Please enter amount!",
    PLEASE_ENTER_MOBILE_NUMBER: "Please enter mobile number",
    PLEASE_CHECK_INTERNET_CONNECTION: "Please check internet connection",
    SOMETHING_WENT_WRONG: "something went wrong !",
    CODE_RESENT: "Code resent!",
    UPDATE_NOTE: "Update Note",
    CUSTOMERS: "Customers",
    SEND_REMINDER: "Send Reminder",
    SHARE_STATEMENT: "Share Statement",
    CREDIT_LIMIT: "Credit Limit",
    CONTACT_US: 'Contact Us',
    ADD_FROM_PHONEBOOK: "Add from Phonebook",
    SEARCH_FROM_PHONEBOOK: "Search from Phonebook",
    NO_TRANSACTIONS_AVAILABLE: 'No Transactions Available',
    CHANGE_LANGUAGE_CONFIRMATION: "This feature requires Restart of App, Do you want to continue?",
    ENTER_YOUR_NOTE_HERE: "Enter your note here...",
    START_DATE: "Start Date",
    END_DATE: "End Date",
    THIS_MONTH: "This month",
    CUSTOM_RANGE: "Custom Range",
    DO_YOU_WANT_TO_DELETE_THIS_CUSTOMER: "Do you want to delete this customer?",
    CREDIT_LIMIT_EXCEED_MSG: "Credit limit exceed, Would you like to continue?",
    WARNING: "Warning",
    INVALID_MOBILE_NO_ERROR: "Invalid Mobile # Valid Format: 3451234567",
    FIELD_REQUIRED: "Field required",
    CREDIT_LIMTI_CANT_BE_ZERO: "Credit Limit can't be Zero (If you don't want to set limit just leave blank)",
    SHARE_MSG: 'Download CreditBook now and manage your Khata Book efficiently. http://onelink.to/z8xrde',
    ADD_YOUR_FIRST_CUSTOMER: 'Add your first customer',
    PRESS_THE_BUTTON_FOR_ADDING_YOUR_FIRST_CUSTOMER: "Press the button to add your first customer!",
    GO_TO_DASHBOARD: "Go to Dashboard",
    TOOLTIP_TEXT_ONBOARDING_WHEN_ADDED_FIRST_CUSTOMER: "You have added your first customer! Click on the customer to either give or receive money from them",
    TOOLTIP_TEXT_ONBOARDING_WHEN_ADDED_FIRST_TRANSACTION: "You have now recorded a transaction! CreditBook has other features. To learn how to use the app you can click the '?' icon to view help videos.",
    ACOUNT_CALL: 'Call',
    ACOUNT_REPORT: 'Reports',
    TRANSACTIONS_ALERT_SETTING: 'Transaction Alert Settings',
    ACCOUNT_PAYMENT_REMINDER_DATE_SET: 'Set Collection Date',
    REPORTS: "VIEW REPORTS",
    REMINDERS: "Reminders",
    REMINDER_HAS_BEEN_SENT: "A Reminder has been sent!",
    MOBILE_NO_NOT_ADDED: "Mobile number not added",
    MOBILE_NO_NOT_ADDED_DESCRIPTION: "The mobile number has not been added. Would you like to add the mobile number?",
    SOMETHING_WENT_WRONG_WHILE_SENDING_SMS: "Something went wrong when sending the message. Please try again!",
    PAYMENT_DUE_DATE: "Payment due date",
    AUTO_REMINDER: "Auto reminder",
    REMINDER_DATE: "Reminder date",
    FREE_SMS: "Free SMS",
    CANCEL: "Cancel",
    REMIND_ALL: "Remind all",
    REMIND: "Remind",
    REMINDER_SENT: "Reminders has been sent!",
    SMS_ABOUT_TO_SEND_ALL: "You are about to send SMS reminders to all the customers with an outstanding balance. Do you want to continue? NOTE: The SMS will only be sent to those customers whose number is added",
    APPLY_FILTERS: "Apply Filters",
    LOWEST_AMOUNT: "Lowest Amount",
    HIGHEST_AMOUNT: "Highest Amount",
    SORT_BY: "Sort By",
    AMOUNT: "Amount",
    ADD_FILTERS: "Add Filters",
    TRANSACTION_ALERTS: "Transaction Alerts",
    TRANSACTION_TOGGLE_MESSAGE: "Transaction alerts are SMS notifications sent to your customer when a transaction is recorded",
    DATA_SECURITY_MESSAGE: "Your data and transactions are completely secure",
    AUTO_REMINDER_MESSAGES: "Auto reminders are sent everyday at 6:00 am",
   
    SCHEDULED_FOR_LATER: "Scheduled for Later",
    TO_BE_SENT: "To be sent",
    APPLOCK: "App Lock",
    CREATE_A_NEW_PIN: "Create a New PIN",
    CONFIRM_PIN: "Confirm PIN",
    PIN_NOT_MATCHED_ERROR: "PIN not matched! Please re-enter",
    APPLOCK_ENABLED: "AppLock Enabled!",
    APP_LOCK_UPDATED: "AppLock Updated!",
    REMOVE_APPLOCK: "Remove AppLock",
    CHANGE_PIN: "Change PIN",
    RE_ENTER_YOUR_PIN: "Re-Enter your PIN",
    REMOVE_PIN: "Remove PIN",
    INVALID_PIN_ERROR: "Invalid PIN, Please Re-enter",
    APP_LOCK_REMOVED: "Applock Removed!",
    ENTER_YOUR_OLD_PIN: "Enter your Old PIN",
    ENTER_YOUR_PIN: "Enter your PIN",
    CONTACT_SUPPORT: "Contact Support",
    FORGOT_YOUR_PIN: "Forgot your PIN?",
    CLICK_HERE: "Click Here",
    DATA_BACKUP: "Data Backup",
    YOUR_DATA_HAS_BEEN_BACKED_UP: "Your data has been backed up!",
    DATA_BACKUP_DESC: "Please connect to the internet to back your data to our servers!",
    LAST_BACKUP_AT: "Last Backup at",
    SMS_ALERT_LANGUAGE: "SMS Alert Language",
    TRANSACTIONAL_SMS_SAMPLE: "Transactional SMS Sample",
    CONFIRM_LANGUAGE: "Confirm Language",
    TOTAL: "Total",
    CUSTOMER_STATEMENT: "Customer Statement",
    ALL: "All",
    BUSINESS_CARD: "Business Card",
    SAMPLE_CARD_DESIGN: "Sample Card Design",
    CREATE_A_NEW_BUSINESS_CARD: "Create a new business card",
    ALTERNATE_PHONE_NUMBER: "Alternate Phone Number",
    LOCATION: "Location",
    AUTOMATICALLY_PICKS_UP_CURRENT_LOCATION_SHOWN_IN_GOOGLE_MAP_BELOW: "Automatically picks up current location shown in Google Maps below",
    SAVE_CARD: "Save Card",
    SHARE: "Share",
    DELETE: "Delete",
    EDIT: "Edit",
    CREATE_NEW_CARD: "Create new card",
    COLLECT_PAYMENTS_3_TIMES_FASTER: "Collect payments 3 times faster!",
    SET_COLLECTION_DATES: "Set Collection Dates",
    REMINDERS_GO_OUT_1_DAY_BEFORE: "Reminders go out 1 day before",
    COLLECT_PAYMENTS_3_X_FASTER: "Collect Payments 3x faster!",
    ADDRESS: "Address",
    BUSINESS_CARD_HAS_BEEN_CREATED: "Business card has been created!",
    BUSINESS_CARD_HAS_BEEN_UPDATED: "Business card has been updated!",
    DATE_RANGE: "Date Range",
    INVALID_AMOUNT: 'Invalid Amount',
    COLLECTION_DATE: 'Collection Date',
    YOUR_DATA_IS_100_SAFE_AND_SECURE: "Your data is 100% safe and secure",
    TRANSACTION_HAS_BEEN_UPDATED: "Transaction has been updated",
    MORE: "More",
    PROMOTE: "Promote",
    ADVERTISE_YOUR_PRODUCTS: "Advertise your products!",
    ADVERTISE_YOUR_PRODUCTS_DESC: "Let your customers know about special promotions happening at your store today via WhatsApp!",
    CREATE_A_NEW_AD: "Create a new Ad",
    STEP_1: "Step 1",
    TAKE_OR_UPLOAD_A_PICTURE_OF_THE_PRODUCT: "Take or Upload a picture of the product you want to promote and add a caption",
    TAK_UPLOAD_A_PICTURE: "Take / Upload a picture",
    CREATE_A_NEW_AD_POST_TO_SHARE: "Create a new ad post to share",
    THIS_IS_WHATSAPP_TEXT_MESSAGE: "This is whatsapp text message",
    STEP_2: "Step 2",
    DO_YOU_WANT_TO_SEND_AN_ACCOMPANYING_MESSAGE: "Do you want to send an accompanying message?",
    PENDING: "Pending",
    UPCOMING: "Upcoming",
    COLLECTION_DATE_NOT_SET: "Collection date not set",
    SELECT_DATE: "Select date",
    SEND_REMINDER: "Send Reminder",
    SELECT_ON_MAP: "Select on Map",
    LOCATION_UPDATED: "Location updated",
    WELCOME_TO_CREDITBOOK: "Welcome to CreditBook",
    MANAGE_FINANCES_TRACK_PAYMENTS: "Manage finances. Track payments. Update receivables. Ab udhaar hogaya digital!",
    LETS_GO: "Let's go!",
    KHATA_AB_PHONE_PE: "Khaata ab phone pe!",
    STAY_INFORMED_AND_BE_IN_CONTROL_OF_CREDIT: "Stay informed and be in control of credit transactions with customers and suppliers.",
    YOUR_DATA_IS_SAFE_AND_SECURED: "Your data is safe and secure",
    TEXT_ABOUT_CLOUD_SECURITY_GOES_HERE: "Text about cloud security goes here and here.",
    ASAAN_HISAAB_KITAAB_ACCOUNTS: "Asaan hisaab kitaab accounts in minutes. No more looking up scraps of papers or arguing with customers.",
    SKIP: "Skip",
    TITLE: "Title",
    ADD_YOUR_CAPTION_HERE: "Add your caption here",
    DONE: "Done",
    AD_INSERTED_SUCCESS: "Ad inserted success!",
    ONLINE_NOTE: "You are using the app in Online mode!",
    OFFLINE_NOTE: "You are using the app in Offline Mode!",
    TITLE_IS_REQUIRED: "Title is required!",
    AD_UPDATED_SUCCESS: "Ad updated success!",
    CAROUSEL_DESC: "Save hours by updating daily business accounts in minutes. No more looking up scraps of papers or arguing with customers.",
    EXISTING_CUSTOMER: "Existing Customer",
    ADD_A_STARTING_BALANCE: "Add a Starting balance",
    STARTING_BALANCE: "Starting balance",
    REFERRALS: "Referrals",
    REFERRAL_CODE: "Referral Code",
    SHARE_NOW: "Share Now",
    TOTAL_REFERRALS: "Total Referrals",
    ACTIVE_REFERRALS: "Active Referrals",
    CB_POINTS: "CB Points",
    INVITE_YOUR_FRIENDS_AND_EARN_CB_POINTS: "Invite your friends and earn CreditBook points",
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    REFERRAL_SHARE_TEXT: "Download CreditBook by clicking on this link for free today and change your business like I did by logging all your transactions like cash sale or udhar given with many other features including data backups and data security!",
    CREATE_NEW_BUSINESS: "Create New Business",
    CREATE_BUSINESS: "Create Business",
    CHOOSE_YOUR_BUSINESS_TYPE: "Choose business type",
    CHOOSE_YOUR_BUSINESS_CATEGORY: "Choose business category",
    CHOOSE_CATEGORY: "Choose Type",
    CHOOSE_CATEGORY: "Choose Category",
    BUSINESS_LOCATION: "Business Location",
    GET_MY_LOCATION: "Get my location",
    BUSINESS_SETTING: "Business Settings",
    UPDATE_BUSINESS: "Update Business",
    // REFERRAL_SHARE_TEXT_1: "Download CreditBook by clicking on this link",
    // REFERRAL_SHARE_TEXT_2: "for free today and change your business like I did by logging all your transactions like cash sale or udhar given with many other features including data backups and data security!",
    ENJOYING_THE_APP_WHY_NOT_GIVE_IT_5_STARS: "Enjoying the App. Why not give it 5 stars !",
    FREE_SMS_LIMIT_REACHED: "Your daily sms sending limit is exceeded for this customer",
    LOGIN_WITH_TOUCHID: "Login with TouchID",
    ENABLE_TOUCHID: "Enable TouchID",
    DISABLE_TOUCHID: "Disable TouchID",
    LOGIN_WITH_BIOMETRIC: "Log in with Biometrics",
    FINGER_PRINT_COMPATIBLE_ERROR: "Your device isn't compatible for finger print",
    OTP_WILL_BE_SEND_ON: "OTP will be sent to",
    VERIFY_FINGER_PRINT: "Verify Finger Print",
    FINGER_PRINT_VERIFIED_SUCCESSFULLY: "Finger Print Verified Successfully",


    TRANSACTIONS_TAGS: "Transaction Tags",
    CASH_IN_HAND: "Cash in hand",
    UDHAAR: "Udhaar",
    EXPENSES: "Expenses",
    PAYMENT: "Payment",
    REPAYMENT: "Repayment",
    CASH_SALE: "Cash sale",
    STOCK_ON_CREDIT: "Stock on credit",
    OTHER: "Other",
    SEND_FREE_SMS_TO_CUSTOMER: "Send Free SMS to",
    SEND_FREE_SMS: "Send SMS",
    NOT_NOW: "Not Now",
    TRANSACTION_NOTE_WITH_MSG: "Transaction SMS with note",
    SELECT_BUSINESS: 'Select Business',
    SELECT_YOUR_BUSINESS_CATEGORY: "Select your business category",
    SELECT_YOUR_BUSINESS_TYPE: "Select your business type",
    WHOLESALER: "Wholesaler",
    RETAILER: "Retailer",
    DISTRIBUTOR: "Distributor",
    TRADER: "Trader",
    PERSONAL_BOOKKEEPING: "Personal Bookkeeping",
    SALES_AGENT: "Sales Agent",
    CONTRACTOR: "Contractor",
    CAR_AUTOMOBILE: "Car, Automobile & Workshop",
    BAKERY_SWEETS: "Bakery / Sweets",
    COSMETICS: "Cosmetics",
    CLOTHES_FABRIC: "Clothes & Fabric",
    ELECTRONICS: "Electronics",
    CONSTRUCTION: "Construction",
    GENERAL_STORE: "General store",
    HARDWARE_SANITARY: "Hardware & Sanitary",
    COMPUTER: "Computer",
    GOLD_JEWELERY: "Gold & Jewelry",
    LOGISTICS_TRANSPORT: "Logistics & Transport",
    MEDICAL_PHARMACY: "Medical & Pharmacy",
    OIL_CHEMICALS: "Oil / Chemicals",
    PAN_KHOKHA: "Pan / Khokha",
    PHOTO_PRINTING: "Photo / Printing",
    POULTRY: "Poultry",
    DAIRY: "Dairy",
    EDUCATION_SCHOOL: "Education & School",
    SHOES: "Shoes",
    SPORTS: "Sports",
    STATIONARY: "Stationary",
    KIRYANA: "Kiryana",
    MOBILE_AND_EASYLOAD: "Mobile and Easyload",
    ALUMINIUM_STEEL_GLASS: "Aluminium, Steel & Glass",
    RESTAURANT_HOTEL: "Restaurant / Hotel",
    PICK_AND_DROP_TAXI_SERVICE: "Pick & Drop / Taxi service",
    BOOKS: "Books",
    TRAVEL: "Travel",
    GARMENTS_AND_TAILOR: "Garments & Tailor shop",
    BUSINESS_CATEGORY: "Business Category",
    BUSINESS_STATEMENT: "Business Statement",
    CB_CREDITS: "CB Credits",



    ENTER_YOUR_DETAILS_TO_GET_STARTED: "Enter your details to get started",
    SIGN_UP: "Sign up",
    YOU_WILL_GIVE: "You Will Give",
    YOU_WILL_GET: "You Will Get",
    SELECT_YOUR_BUSINESS: 'Select Your Business',
    CASHBOOK_REPORT: "Cashbook Report",
    ALL_TIME: "All Time",
    TODAY: "Today",
    YESTERDAY: "Yesterday",
    LAST_WEEK: "Last Week",
    LAST_MONTH: "Last Month",
    CREDITS: "Credits",
    DEBITS: "Debits",
    CASHBOOK: "Cashbook",
    TODAYS_BALANCE: "Today's Balance",
    MANAGE_YOUR_DAILY_CASH_TRANSACTIONS: "Manage Your Daily Cash Transactions",
    TODAYS_BALANCE_DAILY_BALANCE_DAILY_EXPENSES: "Today's Balance: Daily Sales - Daily Expenses",
    CASH_IN_HAND_CURRENT_CASH_BALANCE: "Cash in Hand: Current Cahs Blance inn your Hand/Drawer ",
    PDF_REPORTS_DOWNLOAD: "PDF Reports: Download monthly or daly reports!",
    NEW_TRANSACTION: "New Transaction",
    ADD_ITEMS: "Add Items",
    ADD_CONTACTS: "Add Contacts",
    SAVE: "Save",
    ITEM_NAME: "Item Name",
    ADD_ANOTHER_ITEM: "Add Another Item",
    EDIT_TRANSACTION: "Edit Transaction",
    NAME: "Name",
    CASH: "Cash",
    AMOUNT: "Amount",
    MODE_OF_PAYMENT: "Mode of Payment",
    NOTE: "Note",
    DATE: "Date",
    UPLOAD_PHOTO: "Upload Photo",
    DELETE_ENTRY: "Delete Entry",
    FILTER_BY_TYPE: "Filter By Type",
    CASHBOOK_HELP: "Cashbook Help",
    REPORT_OF: "Report of",
    REPORT: "Reports",
    SALES: "Sales",
    WELCOMEQUOTE: "Manage your business. Send Reminders & Receive payments. All Free",
    START_USING_CREDITBOOK_APP: "Start using CreditBook App",
    SELECT_YOUR_LANGUAGE: "SELECT YOUR LANGUAGE",
    ENTER_YOUR_MOBILE_NUMBER: "Enter Your Mobile number",
    ENTER_MOBILE_NUMBER_QUOTE: "By Clicking you may receive SMS on the filled number. Fill the Code",
    VIDEO_TUTORIAL: "Video Tutorial",
    ADD_YOUR_FIRST_CONTACT: 'Add your first contact',
    NEW_CONTACT: "New Contact",
    SEARCH_CONTACTS: "Search Contacts",
    UPDATE_CONTACT: "Update Contact",
    ADD_CONTACT: "Add Contact",
    ADD_CONTACT_FROM_PHONEBOOK: "Add Contact from Phonebook",
    SEARCH_BY_NAME_OR_PHONE: "Search by Name or Phone Number",
    SAVE_EXIT: "Save & Exit",
    SMS: "SMS",
    REFERRAL_QUOTE: "Share CreditBook With Your Friends & Earn Upto",
    REFERRAL_TEXT: "You will earn Rs.20 for each friend that does 1 transaction after sign up",
    OTP_VERIFICATION: "OTP Verification",
    OTP_VERIFICATION_QUOTE: "Enter the OTP sent to",
    MAINTAIN_SALES_EXPENSES: "Maintain Your Daily Sales and Expenses",
    CASHBOOK_VIDEO_TUTORIAL: "Cashbook Video Tutorial",
    CUSTOMER_HAS_BEEN_DELETED: "Customer has been deleted",
    TRUSTED_BY_ALL_PAKISTANIS: "Trusted by all Pakistanis",
    START: "Start"




}