import React, { useState } from "react";
import {
  Select,
  MenuItem,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Button,
  Snackbar,
} from "@material-ui/core";
import UserMiddleware from "../../../store/middleware/User";
import useStyles from "./styles";
import Loader from "../../common/Loader";
import OnBoardingFooter from "../../common/OnBoardingFooter";
import { strings } from "../../../i18n";
import { phoneNumPattForLogin } from "../../../constants";
import { Alert } from "@material-ui/lab";
import TagManager from 'react-gtm-module'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const Login = ({ history }) => {
  const styles = useStyles();
  const [mobileNo, setMobileNo] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false)
  const sendVerificationCode = () => {
    setOpen(false);
    setLoading(true);

    let number = "+92" + mobileNo;

    UserMiddleware.loginWithCustomToken(number, "Web App")
      .then((res) => {

        const tagManagerArgs = {
          gtmId: 'GTM-PC8XB9L',
          dataLayer: {
            "event": "phone_number_entered",
            "mobile_phone":number ,
            page_title :"Login"
          } 
        }
    
        TagManager.dataLayer(tagManagerArgs)
        setLoading(false);
        console.log(res);
        history.push("/verify", {
          verificationCodeSeed: res.seed,
          mobileNo: number,
          uid : res.uid
        });
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const onChange = (value) => {
    if (value === "0") return;

    if (value.length < 11) setMobileNo(value);
  };
  const openDialog = () => {
 
    if ( !mobileNo  || mobileNo.match(phoneNumPattForLogin) === null ) {
      setError(true)
      return
    }

    if (mobileNo.length === 10) {
     
      sendVerificationCode()
    }
  };
  return (
    <>
      <Loader loading={loading} />
      <Grid
        style={{ minHeight: "90vh" , backgroundColor:"#fff"}}
        direction="row"
        container
        alignItems="center"
        justify="center"
      >
        <Grid item xs={10} sm={7} md={5} lg={4} xl={3}>
          <Grid
            alignItems="center"
            container
            style={{ marginBottom: "50px" }}
            item
            justify="center"
          >
            <Typography className={styles.heading}>
              {strings("ENTER_MOBILE_NUMBER")}
            </Typography>
          </Grid>
          <Grid
            alignItems="center"
            style={{ marginBottom: "10px" }}
            container
            item
            justify="center"
          >
            <Select fullWidth={true} labelId="label" id="select" value="1">
              <MenuItem value={1}>{strings("PAKISTAN_92")}</MenuItem>
            </Select>
          </Grid>
          <Grid alignItems="center" container item justify="center">
            <TextField
              fullWidth={true}
              id="standard-basic"
              placeholder="3331234567"
              label={strings("LOGIN_PHONENUMBER")}
              value={mobileNo}
              type="tel"
              autoFocus
              onChange={(e) => onChange(e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>

      <OnBoardingFooter
        leftText={strings("BACK")}
        leftFunc={() => {
          history.replace("/welcome");
        }}
        rightText={strings("NEXT")}
        rightFunc={() => {
          openDialog()
        }}
      />

      {/* <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {strings("VERIFICATION_CODE")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {strings("OTP_WILL_BE_SEND_ON")} +92 {mobileNo}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            {strings("CANCEL")}
          </Button>
          <Button onClick={() => sendVerificationCode()} color="primary">
            {strings("OK")}
          </Button>
        </DialogActions>
      </Dialog> */}


      <Snackbar open={error} onClose={() => setError(false)}  >

        <Alert onClose={() => setError(false)} severity="error">
          {strings("INVALID_MOBILE_NO_ERROR")}
        </Alert>
      </Snackbar >
    </>
  );
};

export default Login;
