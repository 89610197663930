import { makeStyles } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles((theme) => ({
   
    headerMainContainer: {
        flexDirection: "row",
        height: "64px",
        width: "100%",
        backgroundColor: themeColors.appColor,
        ["@media only screen and (min-device-width: 200px) and (max-device-width: 320px)  "]: {
          height: "50px",
          
        }
        
      },
  
      leftIcon: {
        color: "#fff",
        fontSize: 25,
        marginLeft:"10px",
        backgroundColor: themeColors.appColor,
      },
      menuButton :{
        
 
      },
      titleText:{
        color: themeStyleSheet.fontColor,
        color: '#fff',
        fontSize: props=> props.subTitle ?  themeStyleSheet.fontSize18 :themeStyleSheet.fontSize20,
        ["@media only screen and (min-device-width: 200px) and (max-device-width: 320px)  "]: {           
          width:"150px"
        }
      },

      
      
}));

export default useStyles;
