import "./styles.css";

import { store, persistor } from "../store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import Routes from "../routes";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1400,
    },
  },

 
});
function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes />
        </PersistGate>
      </Provider>
    </MuiThemeProvider>
  );
}

export default App;
