import { LAYOUT } from "./types";

export default class LayoutActions {
    static displayCurrentTransaction( ) {
        return {
            type:  LAYOUT.CURRENT_TRANSACTION_DISPLAYED,
 
        }
    }

    static hideCurrentTransaction( ) {
        return {
            type: LAYOUT.CURRENT_TRANSACTION_HIDDEN,
          
        }
    }

    static displayCurrentStatement( ) {
        return {
            type:  LAYOUT.CURRENT_STATEMENT_DISPLAYED,
 
        }
    }

    static hideCurrentStatement( ) {
        return {
            type: LAYOUT.CURRENT_STATEMENT_HIDDEN,
        }
    }

 
    static openDrawer( ) {
        return {
            type: LAYOUT.OPEN_DRAWER,
        }
    }

    static closeDrawer( ) {
        return {
            type: LAYOUT.HIDE_DRAWER,
        }
    }
}
