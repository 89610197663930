import { makeStyles } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles((theme) => ({
   
  root:{
    marginTop: "10px",
    display:"flex",
    width:"100%",
    ["@media only screen and (min-device-width: 200px) and (max-device-width: 320px)  "]: {
      width:"100%",
    
    }
  },
 
  userRowCont: {
   
  },
  userAvatar: {
    height: 50,
    width: 50,
    marginRight: "10px",
  },
 
  customerNameText: {
    color: themeColors.customerName,
    fontSize: themeStyleSheet.fontSize16,
    fontWeight: "500",
  },
  amountTextcolor:{
    color: props => props.current_balance > 0 ? themeColors.amountPay : props.current_balance === 0 ? themeColors.amountClear : themeColors.amountDue
  },
  customerAmountText:{
    color: themeStyleSheet.productDetailTextColor, 
    fontSize: themeStyleSheet.fontSize18,
    fontWeight: "500",
  },
  divider:{
    width:"100%",
    background:"rgba(0, 0, 0, 0.12)",
    marginTop:"10px" ,
    height: "0.5px"
  },
  label: {
    fontSize: "13px",
    color: themeColors.customerLastTransaction,
  },
 
}));

export default useStyles;
