import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Grid,
  Typography,
  Zoom,
  Fab,
  Paper,
  Hidden,
  Icon,
  useTheme,
  Button,
  CssBaseline,
  Modal,
  Drawer,
  Box,
  ButtonBase
} from "@material-ui/core";
import Loader from "../../common/Loader";
import { Scrollbars } from "react-custom-scrollbars";
import useStyles from "./styles";
import clsx from "clsx";
import DrawerLayout from "../../common/DrawerLayout";
import Account from "../../common/Account";
import SearchBar from "../../common/SearchBar";

import { SORT_OPTIONS } from "../../../constants";
import { useDashboardData } from "./customHooks/useDashboardData";
import AddAccount from "../AddAccount";
import AccountTransaction from "../AccountTransaction";
import Header from "../../common/Header";
import BottomTab from "../../common/BottomTab";
import LayoutActions from "../../../store/actions/Layout";
import AccountHeader from "../../common/AccountHeader";
import DrawerHeader from "../../common/DrawerHeader";
import { formatNum } from "../../../utils";
import { changelocale, strings } from "../../../i18n";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";
import TagManager from 'react-gtm-module'

export const Dashboard = ({ history }) => {


  const dispatch = useDispatch();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [searchVal, setSearchVal] = useState("");
  const [accountId, setAccountId] = useState();
  const [sortType, setSortType] = useState(SORT_OPTIONS[2]);
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
  const { accounts, filteredAccounts, user } = useDashboardData(
    sortType,
    setLoading,
    searchVal
  );
  const classes = useStyles({ current_balance: user?.current_balance });
  useEffect(() => {
    const language = localStorage.getItem("language");
    changelocale(language, true);
  }, []); // execute on mount
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const isMobile = useCheckMobileScreen()
  const handleScrollFrame = (values) => {
    console.log(values.top);
  };
  const onAccountSelect = (id) => {
    console.log(id);
    setAccountId(id);
    dispatch(LayoutActions.displayCurrentTransaction());
  };

  useEffect(() => {
    if (accounts.length > 0 && !isMobile) {
      setAccountId(accounts[0].id)
    }
  }, [])

  const renderAccountList = () => {
    if (loading) return <Loader loading={loading}></Loader>;
    let len = filteredAccounts?.length - 1;
    if (searchVal && filteredAccounts?.length > 0)
      return filteredAccounts.map((item, index) => {
        return (
          <Account
            item={item}
            isLast={index === len}
            onClick={onAccountSelect}
          />
        );
      });
    len = accounts?.length - 1;
    if (searchVal) return <h4> Not Found </h4>;
    return accounts.map((item, index) => {
      return (
        <Account item={item} isLast={index === len} onClick={onAccountSelect} />
      );
    });
  };
  const onCustomerModalClose = () => {
    if(!isCustomerModalOpen){
      const tagManagerArgs = {
        gtmId: 'GTM-PC8XB9L',
        dataLayer: {
          "event": "start_add_customer",
 
        } 
      }
  
      TagManager.dataLayer(tagManagerArgs)
    }
    setIsCustomerModalOpen(!isCustomerModalOpen);
  };
  return (
    <>
      <CssBaseline />
      <DrawerLayout>
        <Grid
          direction="row"
          container
          alignItems="flex-start"
          justify="flex-start"
          className={classes.container}
        >
          <Grid
            container
            direction="row"
            item
            xs={12}
            lg={4}
            xl={3}
            md={4}
            className={classes.leftCont}
          >
            <DrawerHeader
              title={user?.business_name || user?.name}
              leftIcon="menu"
              leftIconFunc={() => dispatch(LayoutActions.openDrawer())}
            />
            <Grid container>
              <Grid
                container
                item
                xs={12}
                className={classes.balanceContainer}
              >
                <Grid item xs={4}  >
                  <Box className={classes.balanceBox} >
                    <Box className={classes.balanceCont} >
                      <Typography
                        className={classes.currentBalTag}
                      >
                        Rs
                    </Typography>
                      <Typography
                        className={clsx(classes.currentBalTag, classes.currentBalAmount)}
                      >
                        {formatNum(Math.abs(user?.current_balance || 0))}
                      </Typography>
                    </Box>
                    <Typography
                      align="center"
                      className={classes.balanceTitleText}
                    >
                      {user?.current_balance < 0 ? strings("BALANCE") : user?.current_balance > 0 ? strings("ADVANCE") : strings("CLEAR")}
                    </Typography>
                  </Box>

                </Grid>
                <Grid container item xs={4} justify="center">
                  <Box className={classes.customersCont}  >
                    <Typography
                      align="center"
                      className={classes.customerCountText}
                    >
                      {user?.no_of_customers}
                    </Typography>
                    <Typography
                      align="center"
                      className={classes.balanceTitleText}
                    >
                      {strings("CUSTOMERS")}
                    </Typography>
                  </Box>
                </Grid>

                <Grid container item xs={4} align="center" justify="center" className={classes.reportCont}>
                  <ButtonBase className={classes.reportsSectionCont} onClick={() => history.push("/userStatement")}>
                    <Icon className={classes.reportIcon}>description</Icon>
                    <Typography
                      align="center"
                      className={classes.reportTitleText}
                    >
                      {strings("REPORTS")}
                    </Typography>
                  </ButtonBase>
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.searchCont}>
                <SearchBar
                  value={searchVal}
                  setValue={setSearchVal}
                  filterValue={sortType}
                  setFilterValue={setSortType}
                  placeholderText={"SEARCH_CUSTOMERS"}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.customerListContainer}>
              <Scrollbars
                renderView={(props) => (
                  <div style={{ ...props.style, overflowX: "hidden" }} />
                )}
                autoHide
                autoHeight
                autoHeightMax={`calc(100% )`}
                autoHideDuration={100}
                autoHideTimeout={500}
                onScrollFrame={handleScrollFrame}
                renderThumbVertical={() => (
                  <div className={classes.thumbStyle}></div>
                )}
              >
                {renderAccountList()}
                <div className={classes.phantomStyle} />
              </Scrollbars>
            </Grid>

            <Hidden mdUp>
              <Zoom
                in={true}
                timeout={transitionDuration}
                style={{
                  transitionDelay: `${transitionDuration.exit}ms`,
                }}
                unmountOnExit
              >
                <Fab className={classes.fab} onClick={onCustomerModalClose}>
                  <Icon className={classes.fabIcon}>person_add</Icon>
                </Fab>
              </Zoom>
            </Hidden>
            <Hidden smDown>
              <Grid
                className={classes.addCustomerCont}
                alignItems="center"
                container
                item
                xs={4}
                xl={3}
                justify="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.addCustomerButton}
                  onClick={onCustomerModalClose}
                >
                  {strings("ADD_CUSTOMER")}
                </Button>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <BottomTab />
            </Hidden>
          </Grid>

          <Modal
            open={isCustomerModalOpen}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            onClose={onCustomerModalClose}
          >
            <AddAccount onModalClose={onCustomerModalClose} />
          </Modal>
          <Grid
            container
            item
            direction="row"
            xs={12}
            lg={8}
            xl={9}
            md={8}
            className={classes.rightCont}
          >
            <AccountTransaction accountId={accountId} />
          </Grid>
        </Grid>
      </DrawerLayout>
    </>
  );
};

export default Dashboard;
