import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccounts,
  getFilteredAccounts,
  getLoggedInUser,
} from "../../../../store/selectors";
import AccountsMiddleware from "../../../../store/middleware/Accounts";
import UserMiddleware from "../../../../store/middleware/User";
export const useDashboardData = (sortType, setLoading, searchVal) => {
  const dispatch = useDispatch();
  const accounts = useSelector(getAccounts);
  const [filteredAccounts, setFilteredAccounts] = useState();
  const user = useSelector(getLoggedInUser);
  useEffect(() => {
    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      console.log(searchVal);
      setLoading(false);
      let temp = [];
      temp = accounts.filter((filteredAccount) =>
        filteredAccount.nameLowerCase.includes(searchVal)
      );
      setFilteredAccounts(temp);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchVal]);
  useEffect(() => {
    setLoading(true);
 
    dispatch(UserMiddleware.getUser(user.id));
    dispatch(AccountsMiddleware.getAccounts(1, sortType))
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [ ]);

  useEffect(() => {
    setLoading(true);

    dispatch(AccountsMiddleware.getAccounts(1, sortType))
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [sortType]);
  return {
    accounts,
    filteredAccounts,
    user,
  };
};
