import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app";
import reportWebVitals from "./reportWebVitals";
import './i18n';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-PC8XB9L'
}

TagManager.initialize(tagManagerArgs)
if (process.env.NODE_ENV !== "development")
    console.log = () => {};
ReactDOM.render(
  <React.StrictMode>
 
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
