import { makeStyles } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    [`${theme.breakpoints.down("sm")} `]: {
      position: "fixed",
      backgroundColor: "white",
      zIndex: 200,
      display: props => props.display ? "block": "none",
    },
  
  },
  container: {
    background: "white",
    padding: 0,
    margin: 0,
    width:"100%",
    alignItems:"flex-end",
    [`${theme.breakpoints.up("lg")}`]: {
      height: "calc( 100vh - 124px)",
    },
    display:"flex",
    flexDirection:"column-reverse",
    overflow: "auto",
    height: "calc( 100vh - 120px)",
    marginBottom:"60px",
  },
  noCustomerIcon: {
    color: themeColors.noCustomerIcon,
    fontSize: 110,
  },
  noCustomerContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: "calc( 100% )",
  },
  noTransactionText: {
    fontSize: 16,
    color: themeColors.appColor,
    textAlign: "center",
  },

  bottomContainer: {
    position: "fixed",
    [`${theme.breakpoints.up("lg")}`]: {
      minHeight: "60px",
    },
    [`${theme.breakpoints.down("sm")}`]: {
      minWidth: "100%",
    },
    bottom: 0,
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    minHeight: "60px",
    backgroundColor: "white",
    right: 0,
  },

  givePaymentBtn: {
    padding: "5px 0px",
    width: "45%",
    backgroundColor: themeColors.amountDue,
    alignItems: "center",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
  },
  givePaymentIcon: {
    fontSize: "20px",
    color: "#fff",
  },
  givePaymentText: {
    color: "white",
    fontSize: 15,
    width: "80%",
    textAlign: "center",
  },
  receivePaymentBtn: {
    padding: "5px 0px",
    width: "45%",
    backgroundColor: themeColors.appColor,
    marginRight: 3,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  receivePaymentText: {
    color: "white",
    fontSize: 15,
    width: "80%",
    textAlign: "center",
  },
  receivePaymentIcon: {
    fontSize: "20px",
    color: "#fff",
    marginRight: 0,
  },
}));

export default useStyles;
