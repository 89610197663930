import { ACCOUNT } from "./types";

export default class Account {
    static getAccounts(value) {
        return {
            type: ACCOUNT.GET_ACCOUNTS,
            value
        }
    }

    static getAllAccounts(value) {
        return {
            type: ACCOUNT.GET_ALL_ACCOUNTS,
            value
        }
    }

    static getCustomersHavingOutstanding(value) {
        return {
            type: ACCOUNT.GET_CUSTOMERS_HAVING_OUTSTANDING,
            value
        }
    }

    static getFilteredCustomersHavingOutstanding(value) {
        return {
            type: ACCOUNT.GET_FILTERED_CUSTOMERS_HAVING_OUTSTANDING,
            value
        }
    }

    static getSearchedCustomersHavingOutstanding(value) {
        return {
            type: ACCOUNT.GET_SEARCHED_CUSTOMERS_HAVING_OUTSTANDING,
            value
        }
    }

    static updateCustomer(value) {
        return {
            type: ACCOUNT.UPDATE_ACCOUNT,
            value
        }
    }

    static updateCustomerInAllCustomers(value) {
        return {
            type: ACCOUNT.UPDATE_ACCOUNT_IN_ALL_ACCOUNTS,
            value
        }
    }

    static getLastVisibleAccount(value) {
        return {
            type: ACCOUNT.GET_LAST_VISIBLE,
            value
        }
    }

    static resetLastVisibleAccount() {
        return {
            type: ACCOUNT.RESET_LAST_VISIBLE,

        }
    }
    static getFilteredAccounts(value) {
        return {
            type: ACCOUNT.GET_FILTERED_ACCOUNTS,
            value
        }
    }

    static addAccount(value) {
        return {
            type: ACCOUNT.ADD_ACCOUNT,
            value
        }
    }

    static addAccountInAllAccounts(value) {
        return {
            type: ACCOUNT.ADD_ACCOUNT_IN_ALL_ACCOUNTS,
            value
        }
    }

}