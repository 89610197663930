import { makeStyles } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles((theme) => ({
  loader:{
    color:themeColors.appColor
  },
  loadingText: {
    fontSize: 16,
    marginTop: 15,
    textAlign: "center",
    color: themeColors.appColor,
  },
}));

export default useStyles;
