import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Grid,
  useTheme,
  Button,
  Icon,
  CssBaseline,
  Typography,
  ButtonBase,
  Modal,
  Slide,
} from "@material-ui/core";

import useStyles from "./styles";
import { strings } from "../../../i18n";
import ADDIMAGE from "../../../assets/ad.png";
import TransactionsMiddleware from "../../../store/middleware/Transactions";
import {
  getAccounts,
  getTransactions,
  getViewStates,
} from "../../../store/selectors";
import { Transaction } from "../../common/Transaction";
import Loader from "../../common/Loader";
import AccountHeader from "../../common/AccountHeader";
import { useHistory } from "react-router-dom";
import CreateTransaction from "../CreateTransaction";
import { formatNum } from "../../../utils";
import LayoutActions from "../../../store/actions/Layout";
import AddAccount from "../AddAccount";
import AccountStatement from "../AccountStatement";
import TransactionInfo from "../TransactionInfo";
import TagManager from 'react-gtm-module'
export const AccountTransaction = ({ ...props }) => {
  const views = useSelector(getViewStates);
  const classes = useStyles({ display: views.CurrentTransaction });
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState();
  const [isCreateTransactionOpen, setIsCreateTransactionOpen] = useState(false);
  const [transactionType, setTransactionType] = useState();
  const transactions = useSelector(getTransactions);
  const accounts = useSelector(getAccounts);
  const [customerModal, setCustomerModalOpen] = useState(false);

  const [selectedTransaction, setSelectedTransaction] = useState();
  const [isNewTransaction, setIsNewTransaction] = useState(true);
  const [showStatement, setShowStatement] = useState(false);
  const [showTransactionInfo, setShowTransactionInfo] = useState(false)
  // Data needed
  useEffect(() => {
    setShowStatement(false);
    const accountId = props.accountId;
   // console.log(accountId);
    setLoading(true);
    if (accountId)
      dispatch(TransactionsMiddleware.getTransactions(accountId))
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });

    setAccount(accounts.find((account) => account.id === accountId));
  }, [props.accountId]);

  useEffect(() => {
    const accountId = props.accountId;
    setAccount(accounts.find((account) => account.id === accountId));
  }, [accounts]);
  const navigateToCreateTransaction = (type) => {
    setIsNewTransaction(true);
    setTransactionType(type);
    if(!isCreateTransactionOpen){

    
    const tagManagerArgs = {
      gtmId: 'GTM-PC8XB9L',
      dataLayer: {
        "event": "start_transaction",
        type :type,
      } 
    }
    TagManager.dataLayer(tagManagerArgs)
  }
    setIsCreateTransactionOpen(!isCreateTransactionOpen);
  };

  const onCustomerModalToggle = () => {
    setCustomerModalOpen(!customerModal);
  };
  const onTransactionClick = (item) => {
    setIsNewTransaction(false);
    setSelectedTransaction(item);
    setTransactionType(item?.transaction_type === "debit" ? "Give" : "Receive");
    setIsCreateTransactionOpen(!isCreateTransactionOpen);
  };

  const onBackPressed = () => {
    if (showStatement) setShowStatement(false);
    else dispatch(LayoutActions.hideCurrentTransaction());
  };

  const getHeaderSubTitle = (account) => {
    let subTitle = "";

    if (account) {
      if (account.current_balance > 0)
        subTitle = `${strings("PAY")} Rs ${formatNum(
          Math.abs(account.current_balance)
        )}`;
      else if (account.current_balance < 0)
        subTitle = `${strings("DUE")} Rs ${formatNum(
          Math.abs(account.current_balance)
        )}`;
      else subTitle = strings("CLEAR");
    }
    return subTitle;
  };

  if (!props.accountId || !account) return null;
  if (loading) return <Loader loading={loading} />;

  if (showStatement)
    return <AccountStatement account={account} onBackPress={onBackPressed} />;
  return (
    <>
      <Grid
        direction="row"
        container
        justify="center"
        alignItems="flex-start"
        className={classes.mainCont}
      >
        <AccountHeader
          title={account && account.name}
          leftIcon="arrow_back"
          subTitle={getHeaderSubTitle(account)}
          titleImgSrc={account && account.img_url}
          titleImgFunc={onCustomerModalToggle}
          leftIconFunc={() => onBackPressed()}
          rightIcon={"description"}
          showAvatar={true}
          rightIconFunc={() => setShowStatement(true)}
        />
        <div className={classes.container}>
          {transactions?.length ? (
            transactions?.map((item, index) => {
              return (
                <Transaction
                  key={index}
                  item={item}
                  onClick={() => onTransactionClick(item)}
                />
              );
            })
          ) : (
            <Grid
              container
              item
              justify="center"
              direction="column"
              alignItems="center"
              className={classes.noCustomerContainer}
            >
              <Icon className={classes.noCustomerIcon}>lock</Icon>
              <Typography className={classes.noTransactionText}>
                Your transactions with {account?.name} are completely Secure and
                Private.{" "}
                {account?.no_of_credits > 0 || account?.no_of_debits > 0
                  ? "Please go Online to see all the transactions"
                  : ""}
              </Typography>
            </Grid>
          )}
        </div>

        <Grid
          className={classes.bottomContainer}
          container
          direction="row"
          alignItems="center"
          xs={8}
          xl={9}
          justify="center"
        >
          <ButtonBase
            className={classes.receivePaymentBtn}
            onClick={() => navigateToCreateTransaction("Receive")}
          >
            <Icon className={classes.receivePaymentIcon}>arrow_downward</Icon>
            <Typography className={classes.receivePaymentText}>
             {strings("RECEIVE_PAYMENT")}
            </Typography>
          </ButtonBase>
          <ButtonBase
            className={classes.givePaymentBtn}
            onClick={() => navigateToCreateTransaction("Give")}
          >
            <Typography className={classes.givePaymentText}>
              {strings("GIVE_PAYMENT")}
            </Typography>
            <Icon className={classes.givePaymentIcon}>arrow_upward</Icon>
          </ButtonBase>
        </Grid>
        <Modal
          open={customerModal}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          onClose={onCustomerModalToggle}
        >

          <AddAccount account={account} onModalClose={onCustomerModalToggle} />
        </Modal>
        <Modal
          open={isCreateTransactionOpen}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          onClose={navigateToCreateTransaction}
        >
          {isNewTransaction ?
            <CreateTransaction
              selectedTransaction={isNewTransaction ? null : selectedTransaction}
              account={account}
              type={transactionType}
              onModalClose={navigateToCreateTransaction}
            />
            :
            <TransactionInfo account={account}  onModalClose = { navigateToCreateTransaction} type={transactionType} selectedTransaction={selectedTransaction} />
          }
        </Modal>
      </Grid>
    </>
  );
};

export default AccountTransaction;
