import API from "../../config/lib";



export async function loginWithCustomToken(mobile_no, signature) {
    return API.get(`/loginWeb`, {
        params: {
            mobile_no, signature,
            
        }, timeout: 80000
    });
}


export async function resendVerificationCode(mobile_no) {
    return API.get(`/resendVerificationCodeFourDigit`, {
        params: { mobile_no }
    });
}

export async function getTokenFromUUID(uid) {
    return API.get(`/getTokenFromUUID`, {
        params: { uid },timeout: 80000
    });
}