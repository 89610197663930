import { makeStyles } from "@material-ui/core/styles";

import { themeStyleSheet, themeColors } from "../../../../../constants";

const useStyles = makeStyles((theme) => ({
  drawerTopCont: {
    height: "25vh",
    padding: "1rem",
    backgroundColor: themeColors.appColor,
    position: "relative",
    minHeight: "150px",
     
  },
  userNameContainer: {
    marginLeft: "1rem",
 
  },
  toggleIcon: {
    position: "absolute",
    top: "10px",

    right: "0px",
    color: "white",
  },
  userAvatar: {
    height: 60,
    width: 60,

    ["@media only screen and (min-device-width: 200px) and (max-device-width: 320px)  "]: {
   display :"none"
    },
  },
  textColor: {
    color: themeColors.whiteColor,
  },
  balanceText: {
    backgroundColor: themeColors.whiteColor,
    color: themeColors.appColor,
    fontWeight: "600",
    borderRadius: "0.2rem",

    position: "absolute",
    color: (props) =>
      props.current_balance < 0
        ? themeColors.amountDue
        : props.current_balance > 0
        ? themeColors.amountPay
        : themeColors.amountClear,

    bottom: 20,
    ["@media only screen and (min-device-width: 200px) and (max-device-width: 320px)  "]: {
      bottom: 10,
 
    },
    padding: "5px 10px",
  },
}));

export default useStyles;
