import React, { useEffect, useState } from "react";
import {
  ButtonBase,
  Grid,
  Icon,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import useStyles from "./styles";

export const NotFound = ({ history }) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        style={{ minHeight: "100vh" }}
        container
        justify="center"
        direction="column"
        alignItems="center"
      >
        <Typography className={classes.notFoundHeading}>404</Typography>
        <Typography className={classes.notFoundText}>Page Not Found</Typography>
      </Grid>
    </>
  );
};

export default NotFound;
