import { makeStyles } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles((theme) => ({
  mainCont: {
    [`${theme.breakpoints.down("sm")} `]: {
      height: "100vh",
      maxWidth: "100%",
 
    },
    position: "fixed",
 
 
    backgroundColor: "#fff",
    padding: "0px",
    margin: "0px",
 
  },
  container: {
    padding: "20px 40px",
    overflow: "auto",
    maxWidth:"500px",
    [`${theme.breakpoints.down("sm")} `]: {
      maxHeight: "calc( 100% - 60px )",
    },
    maxHeight: "calc( 100% - 80px )",
    ["@media only screen and (min-device-width: 200px) and (max-device-width: 320px)  "]: {
      padding: "20px 20px",
    }
  },
  title:{
    fontSize:"20px"
  },
  textFieldCont: {
    marginTop: "10px",
    width: "100%",
  },
 
 
  customerButton: {
    marginTop: "50px",
    padding: "8px 40px",
    borderRadius: "15px",
    backgroundColor: themeColors.appColor,
    ["@media only screen and (min-device-width: 200px) and (max-device-width: 320px)  "]: {
      marginTop: "20px",
       
    },

    "&:hover": {
      backgroundColor: "#353030",
    },
  },
 
}));

export default useStyles;
