export default {
    LOGIN_VERIFICATION_CODE_TEXT: "اک تصدیقی کوڈ بھیج دتا اےاتھے",
    LOGIN_COUNTRY_TEXT: "ملک چنو / پاکستان (92+)",
    LOGIN_PHONENUMBER: "فون نمبر",
    WELCOME: "خوش امدیید",
    LOGIN: "لاگ ان ",
    CHANGE_LANGUAGE: "زبان بدلو",
    SELECT_LANGUAGE: "زبان چنو",
    ADD_ACCOUNT: "نوا اکاؤنٹ شامل کرو",
    PAY: "ادا",
    DUE: "بقایہ",
    CLEAR: "مٹاؤ",
    PAID: "ادا کردتی گئی",
    ADDED_ON: "نوں شامل کیتا",
    TOOK_LOAN: "دا قرضہ لیا",
    RUPEES: "روپیہ",
    SEARCH_CONTACTS: "اکاؤنٹ لبھو",
    SALAM: "سلام",
    NEW_CONTACT: "نوا اکاؤنٹ",
    GIVE_PAYMENT: "تسی دتے",
    RECEIVE_PAYMENT: "تسی لئے",
    CHOOSE_YOUR_LANGUAGE: "اپنی زبان چنو",
    NEXT: "آگے",
    BACK: "پچھے",
    VERIFICATION_CODE: "تصدیقی کوڈ",
    VERIFICATION_CODE_MSG: "اِس نمبرتے اک تصدیقی کوڈ SMS دے زریعے بھیجیا اے",
    A_verification_code_will_be_sent_on_your_number_via_SMS_Service_charges_may_apply: "اک تصدیقی کوڈ تہاڈے نمبر تے بھیج دتا گیا اے ایس۔ایم۔ایس سروس چارجزلاگو ہو سکدےنے",
    DIDNT_GET_CODE: "تصدیقی کوڈ نہیں ملیا ؟",
    RESEND_CODE: "کوڈ دوبارہ بھیجو",
    ENTER_MOBILE_NUMBER: "موبائل نمبر درج کرو",
    PAKISTAN_92: "پاکستان (92+)",
    PERSONAL_INFORMATION: "ذاتی معلومات",
    GET_STARTED: "شروع کرو",
    LOGOUT: "لاگ آؤٹ",
    HOME: "ہوم",
    ADD: "ایڈ",
    NO_ACCOUNT_FOUND_NAMED: "اس نام دا کوئی اکاؤنٹ نہیں لبھا",
    CONTACTS_FROM_PHONEBOOK: "فون بک دے کونٹیکٹس",
    SETTINGS: "سیٹنگز",
    ABOUT_US: "ساڈے بارے وچ",
    PRIVACY_POLICY: "رازداری دی پالیسی",
    HELP: "مدد",
    CONTACT: "رابطہ",
    SORT_NAME: "نام",
    SORT_AMOUNT: "رقم",
    SORT_LATEST: "تازہ ترین",
    SORT_PAYMENT_DUE: "مقررہ ادائیگی",
    NAME: "نام",
    PHONE_NUMBER: "فون نمبر",
    BUSINESS_NAME: "کاروبار دا نام",
    TODAY: "اج",
    YESTERDAY: "پچھلا دن",
    ENTER_AMOUNT: "رقم ڈالو",
    WRITE_NOTE_HERE: "اتھے نوٹ لکہو",
    FULL_NAME: "پورا نام",
    EMAIL_ADDRESS: "ای میل ایڈریس",
    BUSINESS_TYPE: "کاروبار دی قسم",
    TRANSACTION_INFO: "لین دین دی معلومات",
    SYNC_SUCESSFULLY: "اپ ڈیٹ کامیاب",
    NOT_SYNC: "اپ ڈیٹ ناکامیاب",
    SHARE_TRANSACTION: "لین دین (دی معلومات) بانٹو",
    DELETE_TRANSACTION: "لین دین (کی معلومات) ڈیلیٹ کرو",
    NOTE_NOT_SPECIFIED: "نوٹ واضح نہیں",
    ADVANCE: "ایڈوانس",
    ACCOUNT: "اکاؤنٹ",
    WHATSAPP: "واٹس ایپ",
    SHARE: "شئیر",
    VERSION: "ورژن",
    UPDATE: "اپ ڈیٹ",
    UPDATE_ACCOUNT: "اکاؤنٹ اپ ڈیٹ",
    ACCOUNTS: "اکاؤنٹس",
    USER_STATEMENT: "صارف دی اسٹیٹمنٹ",
    PROFILE: "پروفائل",
    LANGUAGE: "زبان",
    BALANCE: "بیلینس",
    NOTE: "نوٹ",
    DELETE_ACCOUNT: "اکاؤنٹ ڈیلیٹ ",
    NET_BALANCE: "کُل بیلینس",
    CREDITS: "کریڈٹس",
    PAYMENTS: "ادائیگیاں",
    DO_YOU_WANT_TO_DELETE_THIS_TRANSACTION: "کیا تسی اہے لین دین نو ڈیلیٹ کرنا اے؟",
    TRANSACTION_HAS_BEEN_DELETED: "لین دین ڈیلیٹ کردتی اے",
    RESTORE_TRANSACTION: "لین دین بحال کرو",
    DO_YOU_WANT_TO_RESTORE_THIS_TRANSACTION: "کیا تسی اہے لین دین بحال کرنا چاہندے ہو؟",
    TRANSACTION_HAS_BEEN_RESTORED: "لین دین بحال ہو گئی",
    VERIFIED_BY_CREDIT_BOOK: "کریڈٹ بک توں تصدیق شدہ",
    AMOUNT_GIVEN: "دی گئی رقم",
    AMOUNT_RECEIVED: "وصولی رقم",
    PLEASE_ENTER_AMOUNT: "مہربانی نال رقم درج کرو",
    PLEASE_ENTER_MOBILE_NUMBER: "مہربانی نال موبائل نمبر درج کرو",
    PLEASE_CHECK_INTERNET_CONNECTION: "مہربانی نال انٹرنیٹ کنکشن چیک کرو",
    SOMETHING_WENT_WRONG: "اوتیری!کجھ رولاہو گیا",
    CODE_RESENT: "کوڈ دوبارہ بھیج دتا",
    UPDATE_NOTE: "اپ ڈیٹ نوٹ",
    ADD_CUSTOMER: "ایڈ صارف",
    SEARCH_CUSTOMERS: "تلاش صارف",
    NEW_CUSTOMER: "نواصارف",
    NO_CUSTOMER_FOUND_NAMED: "صارف نہیں ملیا",
    UPDATE_CUSTOMER: "اپ ڈیٹ صارف",
    DELETE_CUSTOMER: "ڈیلیٹ صارف",
    CUSTOMERS: "صارفین",
    SEND_REMINDER: "یاد دیہانی کرواو",
    SHARE_STATEMENT: "شئیر سٹیٹمنٹ",
    CREDIT_LIMIT: "کریڈٹ دی حد",
    CONTACT_US: "ساڈےنال رابطہ",
    ADD_FROM_PHONEBOOK: "فون بک کولوایڈ",
    SEARCH_FROM_PHONEBOOK: "فون بک کولو لبھو",
    SORT_TYPE: "تازہ ترین",
    NO_TRANSACTIONS_AVAILABLE: "کوئی لین دین موجودنہیں",
    TRANSACTION_MESSAGE_WHEN_GIVING: "تسی قرض لیا اےRs:{قیمت}،{دکان دانام}کولو{تاریخ} نو۔ اےبھجیاگیااےکریڈٹ بک تو۔ ہنی کریڈٹ بک ڈاونلوڈکروتےاپنی کھاتہ کتاب نوموثرطریقہ نال سمبھالو۔",
    TRANSACTION_MESSAGE_WHEN_TAKING: "اپنے Rs:{قیمت} دتےآ {دکان دانام}نو {تاریخ}نو۔ اےبھجیاگیااےکریڈٹ بک تو۔ ہنی کریڈٹ بک ڈاونلوڈکروتےاپنی کھاتہ کتاب نوموثرطریقہ نال سمبھالو۔",
    SHARE_MSG: "ہنی کریڈٹ بک ڈاونلوڈکروتےاپنی کھاتہ کتاب نوموثرطریقہ نال سمبھالو۔http://onelink.to/z8xrde",
    REMINDER_WHEN_CREDIT: "توہاڈاکریڈٹ Rs: {اکاونٹ بیلینس} رہندااے {دکان دانام} تے۔ کریڈٹ بک تواےبھجیاگیااے۔ ہنی کریڈٹ بک ڈاونلوڈکروتےاپنی کھاتہ کتاب نوموثرطریقہ نال سمبھالو۔http://tiny.cc/rmmsmz",
    REMINDER_WHEN_BALANCE: "توہاڈا اکاؤنٹ بیلنس Rs : {اکاؤنٹ بیلنس} , {دکان دا نام} تے۔ ہنی کریڈٹ بک ڈاونلوڈکروتےاپنی کھاتہ کتاب نوموثرطریقہ نال سمبھالو۔",
    CONTACT_US_MESSAGE: "سلام ! مینو مدد چائیدی کریڈٹ بک app دے استعمال اچ",
    CHANGE_LANGUAGE_CONFIRMATION: "اِس خصوصیت دے لئے توانو آپ بند کر کے ، دوبارہ شروع کرنا ہو ےگا . کیاتسی کرنا چاہندےہو؟",
    USER_CONSENT_MESSAGE: "سائن اپ کرکےتسی ساڈی شرائط و ضوابط تے رازداری دی پالیسی کولو اتفاق رکھدے ہو .",
    START_DATE: "تاریخ شروع",
    END_DATE: "تاریخ ختم",
    THIS_MONTH: "اے مہینہ",
    CUSTOM_RANGE: "اپنی مرضی دے مطابق",
    CREDIT_LIMIT_EXCEED_MSG: "کریڈٹ حد تو تجاوز ، کیا تسی جاری رکھنا پسند کرو گے؟",
    WARNING: "خبردار",
    INVALID_MOBILE_NO_ERROR: "غلط موبائل # درست فورمیٹ : 3451234567",
    FIELD_REQUIRED: "فیلڈ درکار",
    CREDIT_LIMTI_CANT_BE_ZERO: "کریڈٹ دی حد صفر(0) نہیں ہوسکدی (جےتسی حد مقرر کرنا نہیں چاہندے او تےانو خالی چھوڑدیو)",
    DO_YOU_WANT_TO_DELETE_THIS_CUSTOMER: "کیا تسی اس صارف نو ڈلیٹ کرنا چاہندے ہو؟",
    ENTER_YOUR_NOTE_HERE: "اپنا نوٹ اتھے درج کرو",
    ADD_YOUR_FIRST_CUSTOMER: 'اپنا پہلا صارف داخل کرو۔',
    PRESS_THE_BUTTON_FOR_ADDING_YOUR_FIRST_CUSTOMER: "اپنے پہلے صارف نوں ایڈ کرن دے لئی بٹن نوں دباوٴ۔",
    GO_TO_DASHBOARD: "ڈیش بورڈ تے جاوٴ۔",
    TOOLTIP_TEXT_ONBOARDING_WHEN_ADDED_FIRST_CUSTOMER: "تسی اپنا پہلا صارف درج کر لیا اے! پیسوں دی وصولی یا ادائیگی دے لئی صارف تے کلک کرو۔",
    TOOLTIP_TEXT_ONBOARDING_WHEN_ADDED_FIRST_TRANSACTION: 'تسی لین دین درج کر لئی اے! CreditBook وچ ہوروی خصوصیات موجود نے مزید جانن دے لئی آئی کن (?) تے کلک کرو مددگار ویڈیوز ویکھن دے لئی۔',
    REMINDER_HAS_BEEN_SENT: "یاد دہانی کروادتی",
    MOBILE_NO_NOT_ADDED: "موبائل نمبر موجود نئی",
    MOBILE_NO_NOT_ADDED_DESCRIPTION: "دا موبائل نمبر موجود نئی۔ کیا تسی موبائل نمبر درج کرنا پسند کروگے؟  ",
    SOMETHING_WENT_WRONG_WHILE_SENDING_SMS: "ایس ایم ایس بھیجدے وقت کوئی خرابی آئی اے۔ برائے مہربانی دوبارہ کوشش کرو!",
    REPORTS: "رپورٹاں",
    REMINDERS: "یادہانی",
    PAYMENT_DUE_DATE: "ادائیگی دی آخری تاریخ",
    ACOUNT_CALL: 'کال',
    ACCOUNT_PAYMENT_REMINDER_DATE_SET: 'ادائیگی دی یادہانی دی تاریخ درج کرو',
    AUTO_REMINDER: "خُود کار یادہانی",
    REMINDER_DATE: "یادہانی دی تاریخ",
    FREE_SMS: "مفت دا ایس ایم ایس",
    REMIND_ALL: "سب نوں ياد دلاوں",
    REMIND: "ياد دلاوں",
    REMINDER_SENT: "یادہانی بھیج دتی گئی",
    SMS_ABOUT_TO_SEND_ALL: "تسی ایس ایم ایس بھیجن والے ہو، سارے اُوناں صارفین نوں جنہاں نے بقیہ رقم دینی اے! کی تسی جاری رکھنا چاہوگے؟ نوٹ : ایس ایم ایس صرف اُوناں صارفین نوں بھیجیا جاوگا جنا دا نمبر درج اے۔",
    APPLY_FILTERS: "فلٹرز چلاو",
    LOWEST_AMOUNT: "گھٹ ترین رقم",
    HIGHEST_AMOUNT: "سب توں زیادہ رقم",
    SORT_BY: "ترتیب بذریعہ",
    AMOUNT: "رقم",
    ADD_FILTERS: "فلٹرز لگاو",
    TRANSACTIONS_ALERT_SETTING: "لین دین اطلاع سیٹنگز",
    TRANSACTION_ALERTS: "لین دین اطلاعات",
    TRANSACTION_TOGGLE_MESSAGE: "لین دین اطلاعات، ایس ایم ایس دے بذریعہ تہاڈے صارف نوں جاندی اے جدو وی لین دین عمل وچ آندی اے",
    DATA_SECURITY_MESSAGE: "تہاڈا ڈیٹا تے لین دین مکمل طورتے محفوظ اے",
    AUTO_REMINDER_MESSAGES: "ہر روز صبح دے 6 وجے یادہانی خود بہ خود بھج دتی جاندی اے",
    SCHEDULED_FOR_LATER: "بعد دے لئے مقرر",
    TO_BE_SENT: "بھیجنا اے",
    DEBITS: "ڈیبٹ",
    APPLOCK: "ایپ لاک",
    CREATE_A_NEW_PIN: "نوی پن بناؤ",
    CONFIRM_PIN: "تصدیق پن",
    PIN_NOT_MATCHED_ERROR: "پن وچ مماثلت نئی برائے مہربانی فر درج کرو",
    APPLOCK_ENABLED: "ایپ لاک لا دتا",
    APP_LOCK_UPDATED: "ایپ لاک اپ ڈیٹڈ",
    REMOVE_APPLOCK: "ایپ لاک ہٹاؤ",
    CHANGE_PIN: "پن تبدیل",
    RE_ENTER_YOUR_PIN: "پن فر درج کرو",
    REMOVE_PIN: "پن ہٹاؤ",
    INVALID_PIN_ERROR: "غلط پن براہ مہربانی فر درج کرو",
    APP_LOCK_REMOVED: "ایپ لاک ہٹ گیا",
    ENTER_YOUR_OLD_PIN: "پرانی پن درج کرو",
    ENTER_YOUR_PIN: "پن درج کرو",
    CONTACT_SUPPORT: "رابطہ کرو مدد دےلئی",
    FORGOT_YOUR_PIN: "پن بُھل گئے",
    CLICK_HERE: "کلک اتھے",
    DATA_BACKUP: "ڈیٹا بیک اپ",
    YOUR_DATA_HAS_BEEN_BACKED_UP: "تہاڈا ڈیٹا بیک اپ ہو گیا",
    DATA_BACKUP_DESC: "انٹرنیٹ نال کنیکٹ کرو اپنے ڈیٹا نوں ساڈے سرورز تے بیک اپ کرن دے لئی",
    LAST_BACKUP_AT: "آخری بیک اپ ہوا",
    SMS_ALERT_LANGUAGE: "ایس ایم ایس الرٹ زبان",
    TRANSACTIONAL_SMS_SAMPLE: "لین دین دے ایس ایم ایس دی مثال",
    CONFIRM_LANGUAGE: "زبان کنفرم",
    TOTAL: "کُل",
    CUSTOMER_STATEMENT: "صارف سٹیٹمنٹ",
    ALL: "سب",
    BUSINESS_CARD: "بزنس کارڈ",
    SAMPLE_CARD_DESIGN: "عام کارڈ ڈیزائن",
    CREATE_A_NEW_BUSINESS_CARD: "نوا بزنس کارڈ بناؤ",
    ALTERNATE_PHONE_NUMBER: "متبادل فون نمبر",
    LOCATION: "لوکیشن",
    AUTOMATICALLY_PICKS_UP_CURRENT_LOCATION_SHOWN_IN_GOOGLE_MAP_BELOW: "تھلے دتی گئی لوکیشن خود بہ خود گوگل میپ دے نال چن لوےگا",
    SAVE_CARD: "سیو کارڈ",
    SHARE: "شیئر",
    DELETE: "ڈیلیٹ",
    EDIT: "ایڈٹ",
    CREATE_NEW_CARD: "نوا کارڈ بناؤ",
    COLLECT_PAYMENTS_3_TIMES_FASTER: "ادائیگی 3 گنا تیزی نال حاصل کرو!",
    SET_COLLECTION_DATES: "تاریخ طے کرو حاصل کرن دی",
    REMINDERS_GO_OUT_1_DAY_BEFORE: "یادہانی اک دن پہلو کروادتی جاؤ گی",
    COLLECT_PAYMENTS_3_X_FASTER: "ادائیگی 3X تیزی نال حاصل کرو!",
    ADDRESS: "پته",
    BUSINESS_CARD_HAS_BEEN_CREATED: "بزنس کارڈ بن گیا اے!",
    BUSINESS_CARD_HAS_BEEN_UPDATED: "بزنس کارڈ اپڈیٹ ہوگیا اے!",
    DATE_RANGE: "ڈاٹا رینج",
    INVALID_AMOUNT: "غلط رقم",
    COLLECTION_DATE: "جمع کرن دی تاریخ",
    YOUR_DATA_IS_100_SAFE_AND_SECURE: "تہاڈا ڈیٹا 100٪ بہ حفاظت تے محفوظ اے",
    TRANSACTION_HAS_BEEN_UPDATED: "لین دین اپڈیٹ ہوگئی اے",
    MORE: "ہور",
    PROMOTE: "اگے ودانا",
    ADVERTISE_YOUR_PRODUCTS: "اپنے پروڈکٹس دا اشتہار",
    ADVERTISE_YOUR_PRODUCTS_DESC: "اج دی خصوصی پروموشن دا اپنے صارفین نو دسو WhatsApp دے نال!",
    CREATE_A_NEW_AD: "نوا اشتہار بناؤ",
    STEP_1: "پہلا قدم",
    TAKE_OR_UPLOAD_A_PICTURE_OF_THE_PRODUCT: "جس پروڈکٹ نو تسی پروموٹ کرنا چاہندے ہو اُس دی تصویر لؤ یا اپلوڈ کرو تے کیپشن لکھو",
    TAK_UPLOAD_A_PICTURE: "تصویر لیں / اپلوڈ",
    CREATE_A_NEW_AD_POST_TO_SHARE: "شیئر کرن دے لئی اک نوی اشتہاری پوسٹ بناؤ",
    THIS_IS_WHATSAPP_TEXT_MESSAGE: "اہے WhatsApp ٹیکسٹ میسج اے",
    STEP_2: "دوسرا قدم",
    DO_YOU_WANT_TO_SEND_AN_ACCOMPANYING_MESSAGE: "کی تسی ایدےنال اک پیغام بھیجنا چاہندے ہو؟",
    PENDING: "زیر غور",
    UPCOMING: "آن والی",
    COLLECTION_DATE_NOT_SET: "جمع کرن دی تاریخ مقرر نئی اے",
    SELECT_DATE: "تاریخ منتخب کرو",
    SEND_REMINDER: "یاد دہانی بھیجو",
    SELECT_ON_MAP: "نقشے تے منتخب کرو",
    LOCATION_UPDATED: "جگہ اپڈیٹ ہوگئی اے",
    WELCOME_TO_CREDITBOOK: "کریڈٹ بُک وچ خوش آمدید",
    MANAGE_FINANCES_TRACK_PAYMENTS: "مال نو سنبھالو۔ ادائیگی ٹریک کرو۔ اپڈیٹ رسیوابلز۔  ہن ادھار ہوگیا اے ڈیجیٹل!",
    LETS_GO: "چلو!",
    KHATA_AB_PHONE_PE: "کھاتہ ہن فون تے!",
    STAY_INFORMED_AND_BE_IN_CONTROL_OF_CREDIT: "مطالعہ رہو تے صارفین تے سپلائرز دے نال کریڈٹ دی لین دین نظروچ رکھو۔",
    YOUR_DATA_IS_SAFE_AND_SECURED: "تہاڈا ڈاٹا با حفاظت تے محفوظ اے",
    TEXT_ABOUT_CLOUD_SECURITY_GOES_HERE: "کلاؤڈ سیکیورٹی دے بارے وچ پیغام اتھے تے اتھے آندے نے",
    ASAAN_HISAAB_KITAAB_ACCOUNTS: "سوکھا حساب کتاب اکاؤنٹس منٹاں وچ۔ واوا سارے پیپرز توں گزرنا تے صارفین نال بحث کرنا ہن ہور نئی۔",
    SKIP: "چھڈ دیو",
    TITLE: "ٹائٹل",
    ADD_YOUR_CAPTION_HERE: "اپنےکیپشن اتھے لکھو",
    DONE: "ہوگیا اے",
    AD_INSERTED_SUCCESS: "اشتہار لگ گیااے!",
    ONLINE_NOTE: "تسی آنلائن موڈ وچ ایپ استمال کر ریے ہو!",
    OFFLINE_NOTE: "تسی آفلین موڈ وچ ایپ استمال کر ریے ہو!",
    TITLE_IS_REQUIRED: "ٹائٹل دی لوڈ اے!",
    AD_UPDATED_SUCCESS: "اشتہار اپڈیٹ!",
    CAROUSEL_DESC: "روزآنہ اپنے کاروباری اکاؤنٹ نوں اپڈیٹ کرکے اپنا ٹائم بچاؤ۔ کسٹمر نال بحث تے پیپرز وچ ٹائم ضائع کرنا ہن بلکل نئی۔",
    EXISTING_CUSTOMER: "موجودہ کسٹمر",
    ADD_A_STARTING_BALANCE: "شروع آلا بیلنس پاؤ",
    STARTING_BALANCE: "شروع آلا بیلنس",
    REFERRALS: "ریفرالس",
    REFERRAL_CODE: "ریفرال کوڈ",
    SHARE_NOW: "ہونی شیئرکرو",
    TOTAL_REFERRALS: "کُل رفرالس",
    ACTIVE_REFERRALS: "ایکٹو رفرالس",
    CB_POINTS: "CB پوائنٹس",
    INVITE_YOUR_FRIENDS_AND_EARN_CB_POINTS: "اپنے دوستاں نوں دعوت دیوتے Credit Book پوائنٹس حاصل کرو",
    ACTIVE: "ایکٹو",
    INACTIVE: "ان ایکٹو",
    REFERRAL_SHARE_TEXT: "اج مفت وچ Credit Book ڈاونلوڈ کرو اس لنک تے کلک کرکے تے اپنے کاروبار نوں تبدیل کرو جدا میں کیتا اپنی ساری لین دین جدا نقدِ فروخت یا ادھار تے ہوروی واوا سارے فیچرز موجود نے بیک اپس تے ڈاٹا سیکیورٹی دے نال!",
    ENJOYING_THE_APP_WHY_NOT_GIVE_IT_5_STARS: "ایپ توں لطف اندوز ہو ریے ہو۔ کیوں نہ انو 5 سٹار دیو!",
    FREE_SMS_LIMIT_REACHED: "تہاڈے روزانہ دی ایس ایم ایس دی حد اس کسٹمر دے لئی تجاوز کر گئی اے",
    LOGIN_WITH_TOUCHID: "ٹچ آئیڈی دے زریعے لاگ ان",
    ENABLE_TOUCHID: "ٹچ آئیڈی انیبل",
    DISABLE_TOUCHID: "ٹچ آئیڈی ڈس ایبل",
    OTP_WILL_BE_SEND_ON: "او ٹی پی بھجیا جاؤ گا اتھے",
    CHOOSE_YOUR_BUSINESS_CATEGORY: "اپنے کاروبار دی کیٹیگری چنو",
    CHOOSE_TYPE: "اقسام چنو",
    CHOSE_CATEGORY: "کیٹیگری چنو",
    CREATE_BUSINESS: "کاروبار بناؤ",
    BUSINESS_SETTING: "کاروبار سیٹنگ",
    UPDATE_BUSINESS: "اپڈیٹ کاروبار",
    FINGER_PRINT_COMPATIBLE_ERROR: "تہاڈی ڈیوائس فنگر پرنٹ دے لئی کمپٹیبل نہیں اے",
    VERIFY_FINGER_PRINT: "فنگر پرنٹ تصدیق",
    FINGER_PRINT_VERIFIED_SUCCESSFULLY: "فنگر پرنٹ کامیابی دے نال تصدیق",
    TRANSACTIONS_TAGS: "لین دین کے  ٹیگ ",
    CASH_IN_HAND: "ہاتھ میں کیش",
    UDHAAR: "ادھار",
    EXPENSES: "اخراجات",
    PAYMENT: "ادائیگی",
    REPAYMENT: "ادائیگی",
    CASH_SALE: "نقد فروخت",
    STOCK_ON_CREDIT: "ادھار پر مال",
    OTHER: "دیگر",
    SEND_FREE_SMS_TO_CUSTOMER: "کسٹمر کو مفت ایس ایم ایس بھیجیں",
    SEND_FREE_SMS: "مفت ایس ایم ایس بھیجیں",
    NOT_NOW: "ابھی نہیں",
    TRANSACTION_NOTE_WITH_MSG: "نوٹ کے ساتھ لین دین کا پیغام",
    SELECT_BUSINESS: "بزنس منتخب کریں",
    SMS_SENDING_FAILED: "ایس ایم ایس بھیجنا ناکام ہوگیا براہ کرم اپنا انٹرنیٹ چیک کریں",
    CREATE_NEW_TAG: "نیا ٹیگ بنائیں",
    CUSTOMER_WEB_APP: "کسٹمر ویب ایپ",
    HEY_WELCOME_TO_CREDIT_BOOK: "کریڈٹ بک میں خوش آمدید",
    HEY_WELCOME_TO_CREDIT_BOOK_CUSTOMER_WEB_APP: "کریڈٹ بک کی کسٹمر ویب اپلی کیشن میں خوش آمدید ",
    SELECT_YOUR_BUSINESS_CATEGORY: "اپنے کاروبار کی  قسم کا انتخاب کریں",
    TRADER: "تاجر",
    PERSONAL_BOOKKEEPING: "ذاتی کھاتہ",
    CONTRACTOR: "ٹھیکیدار",
    CAR_AUTOMOBILE: "کار ، آٹوموبائل اور ورکشاپ",
    BAKERY_SWEETS: "بیکری / مٹھائیاں",
    COSMETICS: "میک اپ",
    CLOTHES_FABRIC: "کپڑے اور سوٹ",
    ELECTRONICS: "الیکٹرانکس",
    HARDWARE_SANITARY: "ہارڈ ویئر اور سینیٹری",
    COMPUTER: "کمپیوٹر",
    GOLD_JEWELERY: "سونا اور زیورات",
    LOGISTICS_TRANSPORT: "لاجسٹک اور ٹرانسپورٹ",
    MEDICAL_PHARMACY: "میڈیکل اور فارمیسی",
    OIL_CHEMICALS: "تیل / کیمیکل",
    PAN_KHOKHA: "پان / کھوکھا",
    PHOTO_PRINTING: "فوٹو / پرنٹنگ",
    POULTRY: "پولٹری ",
    DAIRY: "دودھ کی دوکان / باڑہ",
    EDUCATION_SCHOOL: "تعلیم و تربیت اور اسکول",
    SHOES: "جوتے",
    SPORTS: "کھیل/ کا سامان",
    STATIONARY: "اسٹیشنری",
    KIRYANA: "کریانہ ",
    MOBILE_AND_EASYLOAD: "موبائل اور ایزی لوڈ",
    ALUMINIUM_STEEL_GLASS: "ایلومینیم ، اسٹیل اور گلاس",
    RESTAURANT_HOTEL: "ڈھابہ / ہوٹل",
    PICK_AND_DROP_TAXI_SERVICE: "پک اینڈ ڈراپ / ٹیکسی سروس",
    BOOKS: "کتابیں",
    TRAVEL: "ٹریول ایجینسی",
    GARMENTS_AND_TAILOR: "کپڑے اور درزی کی دکان",
    BUSINESS_CATEGORY: "کاروبار کی قسم ",
    BUSINESS_STATEMENT: "کاروباری رپورٹ",
    BUSINESS_LOCATION: "کاروبار جگہ",
    WHOLESALER: "ہول سیلر",
    RETAILER: "ریٹیلر",
    DISTRIBUTOR: "ڈسٹیبیوٹر",
    SALES_AGENT: "سیلز ایجنٹ",
    CONSTRUCTION: "تعمیرات",
    GENERAL_STORE: "جنرل سٹور",
    CREATE_NEW_BUSINESS: "نیا کاروبار بنائے",
    CANCEL: "منسوخ کریں",
    CHOOSE_YOUR_BUSINESS_TYPE: " کاروبار کی اقسام چنے",
    GET_MY_LOCATION: "میری لوکیشن",
    TRANSACTIONS_TAGS: "لین دین دا  ٹیگ ",
    CASH_IN_HAND: "ہاتھ وچ کیش",
    UDHAAR: "ادھار",
    EXPENSES: "اخراجات",
    PAYMENT: "ادائیگی",
    REPAYMENT: "ادائیگی",
    CASH_SALE: "نقد فروخت",
    STOCK_ON_CREDIT: "ادھارتے مال",
    OTHER: "دیگر",
    SEND_FREE_SMS_TO_CUSTOMER: "کسٹمر نوں مفت وچ ایس ایم ایس بھیجو",
    SEND_FREE_SMS: "مفت وچ ایس ایم ایس بھیجو",
    NOT_NOW: "ہلے نہیں",
    TRANSACTION_NOTE_WITH_MSG: "نوٹ دے نال لین دین دا پیغام",
    SELECT_BUSINESS: "بزنس منتخب کرو",
    SMS_SENDING_FAILED: "ایس ایم ایس بھیجنا ناکام ہوگیا اے براہ کرم اپنا انٹرنیٹ چیک کرو",
    CREATE_NEW_TAG: "نوا ٹیگ بناؤ",
    CUSTOMER_WEB_APP: "کسٹمر ویب ایپ",
    HEY_WELCOME_TO_CREDIT_BOOK: "کریڈٹ بک وچ خوش آمدید",
    HEY_WELCOME_TO_CREDIT_BOOK_CUSTOMER_WEB_APP: "کریڈٹ بک دی کسٹمر ویب اپلی کیشن وچ خوش آمدید ",
    SELECT_YOUR_BUSINESS_CATEGORY: "اپنے کاروبار دی  قسم دا انتخاب کرو",
    TRADER: "تاجر",
    PERSONAL_BOOKKEEPING: "ذاتی کھاتہ",
    CONTRACTOR: "ٹھیکیدار",
    CAR_AUTOMOBILE: "کار ، آٹوموبائل تے ورکشاپ",
    BAKERY_SWEETS: "بیکری / مٹھائیاں",
    COSMETICS: "میک اپ",
    CLOTHES_FABRIC: "کپڑے تے سوٹ",
    ELECTRONICS: "الیکٹرانکس",
    HARDWARE_SANITARY: "ہارڈ ویئر تے سینیٹری",
    COMPUTER: "کمپیوٹر",
    GOLD_JEWELERY: "سونا تے زیورات",
    LOGISTICS_TRANSPORT: "لاجسٹک تے ٹرانسپورٹ",
    MEDICAL_PHARMACY: "میڈیکل تے فارمیسی",
    OIL_CHEMICALS: "تیل / کیمیکل",
    PAN_KHOKHA: "پان / کھوکھا",
    PHOTO_PRINTING: "فوٹو / پرنٹنگ",
    POULTRY: "پولٹری ",
    DAIRY: "دودھ دی دوکان / باڑہ",
    EDUCATION_SCHOOL: "تعلیم و تربیت تے اسکول",
    SHOES: "جوتے",
    SPORTS: "کھیل/ دا سامان",
    STATIONARY: "اسٹیشنری",
    KIRYANA: "کریانہ ",
    MOBILE_AND_EASYLOAD: "موبائل تے ایزی لوڈ",
    ALUMINIUM_STEEL_GLASS: "ایلومینیم ، اسٹیل تے گلاس",
    RESTAURANT_HOTEL: "ڈھابہ / ہوٹل",
    PICK_AND_DROP_TAXI_SERVICE: "پک اینڈ ڈراپ / ٹیکسی سروس",
    BOOKS: "کتابیں",
    TRAVEL: "ٹریول ایجینسی",
    GARMENTS_AND_TAILOR: "کپڑے تے درزی دی دکان",
    BUSINESS_CATEGORY: "کاروبار دی قسم ",
    BUSINESS_STATEMENT: "کاروباری رپورٹ",
    ENTER_YOUR_DETAILS_TO_GET_STARTED: "شروع کرنے کے لئے اپنی تفصیلات درج کریں",
    SIGN_UP: "سائن اپ",
    CB_CREDITS: "CB کریڈٹ "






























}