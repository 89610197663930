import React from "react";

import { Grid, Icon, Typography, } from "@material-ui/core";

import useStyles from "./styles";
import { strings } from "../../../i18n";

export const NotificationMessage = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container item justify="center"     style ={{height :"fit-content"}}>
        <Grid
          container
          item
          alignItems="center"
          justify="center"
          className={classes.transactionAlertDesc}
        >
          <Icon className={classes.transactionIcon}>lock</Icon>
          <Typography
            align="center"
            className={classes.transactionAlertDescText}
          >
            {strings("DATA_SECURITY_MESSAGE")}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default NotificationMessage;
