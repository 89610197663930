import { makeStyles } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles({
  heading: {
    fontSize: 22,
    textAlign: "center",
    color: themeColors.appColor,
    fontWeight: 700,
    
  },
});

export default useStyles;
