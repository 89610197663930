import { ACCOUNT } from "../actions/types";
import INITIAL_STATE from "./initialState";



export var accountsReducer = (state = INITIAL_STATE.accounts, action) => {
    switch (action.type) {
        case ACCOUNT.GET_ACCOUNTS:
            return { ...state, accounts: action.value };
        case ACCOUNT.GET_ALL_ACCOUNTS:
            return { ...state, allAccounts: action.value };
        // return { ...state, accounts: action.value.slice(0,1) };
        case ACCOUNT.GET_FILTERED_ACCOUNTS:
            return { ...state, filteredAccounts: action.value };
        // return [];
        case ACCOUNT.GET_CUSTOMERS_HAVING_OUTSTANDING:
            return { ...state, customersHavingOutstandings: action.value };

        case ACCOUNT.GET_FILTERED_CUSTOMERS_HAVING_OUTSTANDING:
            return { ...state, filteredCustomersHavingOutstandings: action.value };

        case ACCOUNT.GET_SEARCHED_CUSTOMERS_HAVING_OUTSTANDING:
            return { ...state, searchedCustomersHavingOutstandings: action.value };

        case ACCOUNT.UPDATE_ACCOUNT:
            console.log("action : ", action)

            let accounts = [...state.accounts];
            // console.log(" accounts : ", accounts)
            let customerToBeUpdated = accounts.find(account => account.id === action.value.customer_id);
            let newCustomerObj = {
                ...customerToBeUpdated,
                ...action.value
            }
            delete newCustomerObj.customer_id;   // deleting 'customer_id' because 'id' exist in object
            let index = accounts.findIndex(account => account.id === action.value.customer_id)
            accounts[index] = newCustomerObj;
            return { ...state, accounts }

        case ACCOUNT.UPDATE_ACCOUNT_IN_ALL_ACCOUNTS:
            console.log("action : ", action)

            let allAccounts = [...state.allAccounts];
            // console.log(" allAccounts : ", allAccounts)
            customerToBeUpdated = allAccounts.find(account => account.id === action.value.customer_id);
            newCustomerObj = {
                ...customerToBeUpdated,
                ...action.value
            }
            delete newCustomerObj.customer_id;   // deleting 'customer_id' because 'id' exist in object
            index = allAccounts.findIndex(account => account.id === action.value.customer_id)
            allAccounts[index] = newCustomerObj;
            return { ...state, allAccounts }

        case ACCOUNT.DELETE_ACCOUNT:
            return { ...state, }
        case ACCOUNT.ADD_ACCOUNT:
            // let newAccounts = [];
            // newAccounts.push(action.value)
            let accountsClone = [action.value, ...state.accounts];
            // accountsClone.push(action.value);
            return { ...state, accounts: accountsClone }
        case ACCOUNT.ADD_ACCOUNT_IN_ALL_ACCOUNTS:
            // let allAccountsClone = [...state.allAccounts];
            let allAccountsClone = [action.value, ...state.allAccounts];

            // allAccountsClone.push(action.value);
            return { ...state, allAccounts: allAccountsClone }
        case ACCOUNT.GET_LAST_VISIBLE:
            return { ...state, lastAccountVisible: action.value }
        case ACCOUNT.RESET_LAST_VISIBLE:
            return { ...state, lastAccountVisible: null }
        default:
            return state;
    }
}

