import { USER } from "./types";

export default class User {
    static loginUser(value) {
        return {
            type: USER.LOGIN_USER,
            value
        }
    }
    static getUser(value) {
        return {
            type: USER.LOGIN_USER,
            value
        }
    }

    static logoutUser(value) {
        return {
            type: USER.LOGOUT_USER
        }
    }

    static updateUser(value) {
        return {
            type: USER.UPDATE_USER,
            value
        }
    }

}