import { USER } from "../actions/types";
import INITIAL_STATE from "./initialState";



export var userReducer = (state = INITIAL_STATE.loggedInUser, action) => {
    switch (action.type) {
        case USER.LOGIN_USER:
            return action.value;
        case USER.LOGOUT_USER:
            return INITIAL_STATE;
        case USER.UPDATE_USER:
            return action.value;
        default:
            return state;
    }
}

