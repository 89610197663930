import { makeStyles } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    [`${theme.breakpoints.down("sm")} `]: {
      backgroundColor: "white",
      zIndex: 300,
    },
    backgroundColor: "#fff",
    zIndex: 300,
  },

  noteText:{
  fontWeight:"600",
  fontSize:"14px"
  },
  timestampText:{
    color: "#00000",
    fontSize:"16px"
  },
  dateRangeText: {
    marginLeft: "10px",
    fontWeight: "500",
    marginTop: "30px",
    [`${theme.breakpoints.down("sm")} `]: {
      marginTop: "20px",
    },
  },
  datePickerMainCont: {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    marginLeft: "10px",
    marginRight: "10px",
    [`${theme.breakpoints.down("sm")} `]: {
      width: "calc( 50% - 20px )",
    },

    border: "1.5px solid",
    borderColor: themeColors.amountInputBorder,
    borderRadius: 8,
    cursor: "pointer",
  },
  datePickerCalenderIcon: {
    color: themeColors.calendarIcon,
    cursor: "pointer",
  },
  datePcikerDropDownICon: {
    color: themeColors.datePickerDropdown,
    cursor: "pointer",
  },

  tabContainer: {
    marginTop: "20px",
  },
  tabBarContainer: {
    width: "100%",
  },

  reportHeader: {
    padding: "10px 5px",
  },
  noOfCrditTransactionText: {
    fontWeight: "700",
  },
  balanceBox: {
    border: "1px solid black",
    borderRadius: "1px",
    maxWidth: "300px",
  },

  transactionTableCont: {
    height: "calc( 100vh - 315px)",

    color: "black",
  },
  tableHeaderText:{
    fontSize: "14px",
    fontWeight: "700",
    color: "black",
  },
  totalText: {
    fontSize: "16px",
    fontWeight: "600",
    color: "black",
  },
  totalAmountText: {
    fontWeight: "600",
    color: "black",
    fontSize: "16px",
    align: "center",
  },
  creditText:{
    color: "#3bc098",
    fontSize: "16px",
    fontWeight: 'bolder',
  },
  debitText:{
    color: "#cb6363",
    fontSize: "16px",
    fontWeight: 'bolder',
  },
  stickyFooterCell: {
    left: 0,
    bottom: 0,
    zIndex: 2,
    position: "sticky",
    background: "white",
  },
}));

export default useStyles;
