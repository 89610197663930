import { makeStyles } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles((theme) => ({
  root: {
    ["@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) "]: {
      maxWidth: "50%",
    },
    ["@media only screen and (min-device-width: 200px) and (max-device-width: 320px)  "]: {
      backgroundColor:themeColors.whiteColor
    },



  },
  chooseLangCont:{
    ["@media only screen and (min-device-width: 200px) and (max-device-width: 320px)  "]: {
    backgroundColor:themeColors.whiteColor
    }
  },
  imgCont: {
    objectFit: "contain",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 0px",
    height: "30vh",
 
    ["@media only screen and (min-device-width: 200px) and (max-device-width: 320px)  "]: {
      display: 'none'
    },
  },

  chooseLangText: {
    fontSize: 22,
    color: themeColors.appColor,
    marginTop: "30px",
    ["@media only screen and (min-device-width: 200px) and (max-device-width: 320px)  "]: {
      marginTop: "10px",
      marginBottom: "10px",
      fontSize: 20,
    },
  },


  languageItemCont: {
    margin: "10px 0px",
    display: "flex",
    width: "100%",
    padding: "0px 20px",

    ["@media only screen and (min-device-width: 200px) and (max-device-width: 320px)  "]: {
      margin: "5px 10px",
      padding: "0px",
      backgroundColor:  themeColors.whiteColor,
    },
  },
  languageItemContSelected: {
    margin: "10px 0px",
    display: "flex",
    width: "100%",
    padding: "0px 20px",

    ["@media only screen and (min-device-width: 200px) and (max-device-width: 320px)  "]: {
      margin: "5px 10px",
      padding: "0px",
      backgroundColor: "#075E55",
    },
  },

  langText: {
    fontSize: 18,
    color: themeColors.langText,
    textAlign: "left",
    ["@media only screen and (min-device-width: 200px) and (max-device-width: 320px)  "]: {
       marginLeft:"10px"
    }
  },

  langTextSelected: {
    fontSize: 18,
    color: themeColors.blackColor,
    textAlign: "left",

    ["@media only screen and (min-device-width: 200px) and (max-device-width: 320px)  "]: {
      color: themeColors.whiteColor,
      marginLeft:"10px"
    }
  },
  langCheckIcon: {
    fontSize: 25,
    color: themeColors.langCheckIcon,
    ["@media only screen and (min-device-width: 200px) and (max-device-width: 320px)  "]: {
      display: 'none'
    },
  },
}));

export default useStyles;
