import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import { userReducer, accountsReducer,transactionsReducer ,layoutReducer} from "./reducer";
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

let reducers = combineReducers({
    user: userReducer,
    accounts: accountsReducer,
    transactions: transactionsReducer,
    layout:layoutReducer
});

const rootReducer = (state, action) => {
    return reducers(state, action)
}

const loggerMiddleware = createLogger();

const persistConfig = {
    timeout: 0,
    key: 'root',
    whitelist: ['user', 'accounts'],
    storage,
};

const middleware = [];
middleware.push(thunkMiddleware);
 

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(...middleware));
const persistor = persistStore(store);

export { store, persistor };
