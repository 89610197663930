import React, { useState, useEffect, useRef, useLayoutEffect } from "react";

import { Grid, Icon, Typography, ButtonBase } from "@material-ui/core";

import useStyles from "./styles";
import clsx from "clsx";

export const Calculator = ({ history, ...props }) => {
  const classes = useStyles();

  useEffect(() => {
    var buttons = document.querySelectorAll(".MuiGrid-root button");
    buttons = [].slice.call(buttons);
    const keyMapping = {};
    buttons.forEach((button) => {
      if (button.dataset.code) {
        keyMapping[button.dataset.code] = button;
      
      }
      props.onLoad(keyMapping);
    });
  }, []);
  const onClick = (e) => {
    var target = e.currentTarget;
 
    props.onClick(target.dataset.value);
  };
  return (
    <>
      <Grid direction="row" container xs={12}>
        <Grid container item direction="row" xs={12}>
          <Grid container item xs={3} justify="center">
            <ButtonBase
              data-code="55"
              data-value="7"
              className={classes.numpadBtnContainer}
              onClick={onClick}
            >
              <Typography className={classes.calculatorNumpad}>7</Typography>
            </ButtonBase>
          </Grid>
          <Grid container item xs={3} justify="center">
            <ButtonBase
              data-code="56"
              data-value="8"
              className={classes.numpadBtnContainer}
              onClick={onClick}
            >
              <Typography className={classes.calculatorNumpad}>8</Typography>
            </ButtonBase>
          </Grid>
          <Grid container item xs={3} justify="center">
            <ButtonBase
              data-code="57"
              data-value="9"
              className={classes.numpadBtnContainer}
              onClick={onClick}
            >
              <Typography className={classes.calculatorNumpad}>9</Typography>
            </ButtonBase>
          </Grid>
          <Grid container item xs={3} justify="center">
            <ButtonBase
              className={clsx(classes.numpadBtnContainer, classes.iconCont)}
              data-code="8"
              data-value="c"
              onClick={onClick}
            >
              <Icon>backspace_outlined</Icon>
            </ButtonBase>
          </Grid>
        </Grid>

        <Grid container item direction="row" xs={12}>
          <Grid container item xs={3} justify="center">
            <ButtonBase
              data-code="52"
              data-value="4"
              className={classes.numpadBtnContainer}
              onClick={onClick}
            >
              <Typography className={classes.calculatorNumpad}>4</Typography>
            </ButtonBase>
          </Grid>
          <Grid container item xs={3} justify="center">
            <ButtonBase
              data-code="53"
              data-value="5"
              className={classes.numpadBtnContainer}
              onClick={onClick}
            >
              <Typography className={classes.calculatorNumpad}>5</Typography>
            </ButtonBase>
          </Grid>
          <Grid container item xs={3} justify="center">
            <ButtonBase
              data-code="54"
              data-value="6"
              className={classes.numpadBtnContainer}
              onClick={onClick}
            >
              <Typography className={classes.calculatorNumpad}>6</Typography>
            </ButtonBase>
          </Grid>
          <Grid container item xs={3} justify="center">
            <ButtonBase
              className={clsx(classes.numpadBtnContainer, classes.iconCont)}
              data-code="shift+56"
              data-value="*"
              onClick={onClick}
            >
              <Icon className={classes.icon}>clear</Icon>
            </ButtonBase>
          </Grid>
        </Grid>

        <Grid container item direction="row" xs={12}>
          <Grid container item xs={3} justify="center">
            <ButtonBase
              data-code="49"
              data-value="1"
              className={classes.numpadBtnContainer}
              onClick={onClick}
            >
              <Typography className={classes.calculatorNumpad}>1</Typography>
            </ButtonBase>
          </Grid>
          <Grid container item xs={3} justify="center">
            <ButtonBase
              data-code="50"
              data-value="2"
              className={classes.numpadBtnContainer}
              onClick={onClick}
            >
              <Typography className={classes.calculatorNumpad}>2</Typography>
            </ButtonBase>
          </Grid>
          <Grid container item xs={3} justify="center">
            <ButtonBase
              data-code="51"
              data-value="3"
              className={classes.numpadBtnContainer}
              onClick={onClick}
            >
              <Typography className={classes.calculatorNumpad}>3</Typography>
            </ButtonBase>
          </Grid>
          <Grid container item xs={3} justify="center">
            <ButtonBase
              className={clsx(classes.numpadBtnContainer, classes.iconCont)}
              data-code="shift+189"
              data-value="-"
              onClick={onClick}
            >
              <Icon className={classes.icon}>remove</Icon>
            </ButtonBase>
          </Grid>
        </Grid>

        <Grid container item direction="row" xs={12}>
          <Grid container item xs={3} justify="center">
            <ButtonBase
              className={classes.numpadBtnContainer}
              data-code="190"
              data-value="."
              onClick={onClick}
            >
              <Typography className={classes.calculatorNumpad}>.</Typography>
            </ButtonBase>
          </Grid>
          <Grid container item xs={3} justify="center">
            <ButtonBase
              data-code="48"
              data-value="0"
              className={clsx(classes.numpadBtnContainer)}
              onClick={onClick}
            >
              <Typography className={classes.calculatorNumpad}>0</Typography>
            </ButtonBase>
          </Grid>
          <Grid container item xs={3} justify="center">
            <ButtonBase
              className={clsx(
                classes.numpadBtnContainer,
                classes.equalBtnContainer
              )}
              data-code="13"
              data-value="="
              onClick={onClick}
            >
              <Icon className={classes.equalIcon}>drag_handle</Icon>
            </ButtonBase>
          </Grid>
          <Grid container item xs={3} justify="center">
            <ButtonBase
              className={clsx(classes.numpadBtnContainer, classes.iconCont)}
              data-code="shift+187"
              data-value="+"
              onClick={onClick}
            >
              <Icon className={classes.icon}>add</Icon>
            </ButtonBase>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Calculator;
