import AccountAction from "../actions/Account";
import firebase from "firebase/app";
import { ACCOUNTS_REF, TRANSACIONS_REF, _CUSTOMERS_REF } from "../../constants";
import { NetInfo } from "../../utils";

import { sendRemindersToCustomers } from "../api/Accounts";

var unsubscribe = null;
var lastVisible = null;
var query = null;
const limit = 10; // should be 20
export default class AccountsMiddleware {
  static getAccounts(pageNo, selectedSortType) {
    return (dispatch, getState) => {
      return new Promise(async (resolve, reject) => {
        if (unsubscribe) {
          try {
            unsubscribe();
          } catch (err) {
            console.log("err : ", err);
          }
        }
        let sortType = "";
        let sortOrder = "";

        switch (selectedSortType) {
          case "SORT_NAME":
            sortType = "nameLowerCase";
            sortOrder = "asc";
            break;
          case "SORT_AMOUNT":
            sortType = "current_balance";
            sortOrder = "desc";
            break;
          case "SORT_LATEST":
            sortType = "last_activity";
            sortOrder = "desc";
            break;
          case "SORT_PAYMENT_DUE":
            sortType = "current_balance";
            sortOrder = "asc";
            break;
          default:
            sortType = "last_activity";
            sortOrder = "desc";
        }

        let { user } = getState();
        query = _CUSTOMERS_REF
          .where("user_id", "==", user.id)
          .where("type", "==", "default")
          .orderBy(sortType, sortOrder); // or order by last_activity

        unsubscribe = query.onSnapshot(async function (querySnapshot) {
          if (!querySnapshot.metadata.fromCache) {
            let allAccounts = [];

            for (let doc of querySnapshot.docs) {
              let docData = doc.data();

              allAccounts.push({
                ...docData,
                is_synced: !doc.metadata.hasPendingWrites,
              });
            }

            dispatch(AccountAction.getAllAccounts(allAccounts));
            dispatch(AccountAction.getAccounts(allAccounts));
            resolve(allAccounts);
          }
        });
      });
    };
  }

  static sortData(sortType, sortOrder, pageNo) {
    console.log("sorting data : ", sortType, sortOrder);
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        let { accounts } = getState();

        let accountsClone = [...accounts.allAccounts];
        accountsClone = accountsClone.slice(0, pageNo * limit);

        accountsClone = accountsClone.filter(
          (account) => account.type === "default"
        );

        if (sortType === "nameLowerCase") {
          console.log("accountsClone before sorting: ", accountsClone);

          accountsClone.sort((a, b) =>
            a.nameLowerCase < b.nameLowerCase
              ? -1
              : a.nameLowerCase > b.nameLowerCase
                ? 1
                : 0
          );
          console.log("accountsClone after sorting: ", accountsClone);
          dispatch(AccountAction.getAccounts(accountsClone));
          resolve();
        } else if (sortType === "current_balance" && sortOrder === "asc") {
          console.log("accountsClone before sorting: ", accountsClone);

          accountsClone.sort((a, b) => a.current_balance - b.current_balance);

          console.log("accountsClone after sorting: ", accountsClone);
          dispatch(AccountAction.getAccounts(accountsClone));
          resolve();
        } else if (sortType === "current_balance" && sortOrder === "desc") {
          accountsClone.sort((a, b) => b.current_balance - a.current_balance);

          console.log("accountsClone after sorting: ", accountsClone);
          dispatch(AccountAction.getAccounts(accountsClone));
          resolve();
        } else if (sortType === "last_activity") {
          accountsClone.sort(
            (a, b) =>
              new Date(b.last_activity).getTime() -
              new Date(a.last_activity).getTime()
          );
          console.log("accountsClone after sorting: ", accountsClone);
          dispatch(AccountAction.getAccounts(accountsClone));
          resolve();
        }
      });
    };
  }

  static unsubscribeQueryListener() {
    if (unsubscribe) {
      console.log("unsubscribeQueryListener.......");
      try {
        unsubscribe();
      } catch (err) {
        console.log("err : ", err);
      }
    }
  }

  static accountStatementData(accountId, startDate, endDate) {
    return (dispatch, getState) => {
      return new Promise(async (resolve, reject) => {
        try {
          let { transactions } = getState();
          let transactionsWithinDateRange = [];

          transactionsWithinDateRange = transactions.filter((transaction) => {
            return (
              transaction.creation_timestamp.toDate() >= startDate &&
              transaction.creation_timestamp.toDate() <= endDate
            );
          });
          console.log(
            "transactionsWithinDateRange ---->",
            transactionsWithinDateRange
          );
          let net_balance = 0;
          let no_of_payments = 0;
          let no_of_credits = 0;
          let total_payments_amount = 0;
          let total_credits_amount = 0;

          for (let i = 0; i < transactionsWithinDateRange.length; i++) {
            if (transactionsWithinDateRange[i].type === "default") {
              if (transactionsWithinDateRange[i].transaction_type === "debit") {
                no_of_credits++;
                total_credits_amount += Number(
                  transactionsWithinDateRange[i].amount
                );
              } else {
                no_of_payments++;
                total_payments_amount += Number(
                  transactionsWithinDateRange[i].amount
                );
              }
            }
          }
          let docRef = _CUSTOMERS_REF.doc(accountId);
          let data = await docRef.get();
          console.log("date : ", data);
          net_balance = data.data().current_balance;
          let obj = {
            no_of_credits,
            total_credits_amount,
            no_of_payments,
            total_payments_amount,
            net_balance,
          };

          resolve(obj);
        } catch (err) {
          reject();
        }
      });
    };
  }

  static getAccountData(accountId) {
    // not using this function now
    return (dispatch, getState) => {
      return new Promise(async (resolve, reject) => {
        try {
          let docRef = _CUSTOMERS_REF.doc(accountId);
          let customerData = await docRef.get();
          customerData = customerData.data();
          console.log("customerData : ", customerData);

          let obj = {
            current_balance: customerData.current_balance,
            no_of_credits: customerData.no_of_credits,
            amount_of_credits: customerData.amount_of_credits,
            no_of_debits: customerData.no_of_debits,
            amount_of_debits: customerData.amount_of_debits,
            no_of_transactions: customerData.no_of_transactions,
            amount_of_total_transactions:
              customerData.amount_of_total_transactions,
          };

          resolve(obj);
        } catch (err) {
          reject();
        }
      });
    };
  }

  static getCustomersHavingOutstandingBalance(pageNo) {
    return (dispatch, getState) => {
      return new Promise(async (resolve, reject) => {
        let { user } = getState();

        let query = _CUSTOMERS_REF
          .where("user_id", "==", user.id)
          .where("type", "==", "default")
          .where("current_balance", "<", 0)
          .limit(pageNo * limit); // or order by last_activity
        try {
          let querySnapshot = await query.get();
          console.log("querySnapshot : ", querySnapshot);
          let customers = [];
          for (let doc of querySnapshot.docs) {
            let docData = doc.data();
            customers.push(docData);
          }
          console.log("customers : ", customers);
          dispatch(AccountAction.getCustomersHavingOutstanding(customers));
          resolve();
        } catch (err) {
          console.log("getCustomersHavingOutstandingBalance err : ", err);
          reject();
        }
      });
    };
  }

  static getFilteredCustomersHavingOutstandingBalance(filteredOptions) {
    return (dispatch, getState) => {
      return new Promise(async (resolve, reject) => {
        let { user } = getState();
        let {
          selectedAmountFilter,
          filterInputAmount,
          sortBy,
        } = filteredOptions;
        console.log("filteredOptions : ", filteredOptions);
        let amountFilter = "";

        switch (selectedAmountFilter) {
          case "Greater Than":
            amountFilter = "<=";
            break;
          case "Equal To":
            amountFilter = "==";
            break;
          case "Less Than":
            amountFilter = ">=";
            break;
          default:
            amountFilter = "<=";
            break;
        }

        let query = _CUSTOMERS_REF
          .where("user_id", "==", user.id)
          .where("type", "==", "default")
          .where("current_balance", "<", 0)
          .where("current_balance", amountFilter, filterInputAmount)
          .orderBy("current_balance", sortBy);

        try {
          let querySnapshot = await query.get();
          console.log("querySnapshot : ", querySnapshot);

          let customers = [];
          for (let doc of querySnapshot.docs) {
            let docData = doc.data();
            customers.push(docData);
          }
          console.log("customers : ", customers);
          dispatch(
            AccountAction.getFilteredCustomersHavingOutstanding(customers)
          );
          resolve();
        } catch (err) {
          reject();
        }
      });
    };
  }

  static searchCustomersHavingOutstandingBalance(input) {
    return (dispatch, getState) => {
      return new Promise(async (resolve, reject) => {
        let { user } = getState();
        let query = _CUSTOMERS_REF
          .where("user_id", "==", user.id)
          .where("type", "==", "default")
          .orderBy("nameLowerCase")
          .startAt(input)
          .endAt(input + "\uf8ff")
          .limit(10);
        try {
          let querySnapshot = await query.get();
          console.log("querySnapshot : ", querySnapshot);
          let customers = [];
          for (let doc of querySnapshot.docs) {
            let docData = doc.data();
            customers.push(docData);
          }
          customers = customers.filter(
            (customer) => customer.current_balance < 0
          );
          customers = [...customers];
          console.log("customers : ", customers);
          dispatch(
            AccountAction.getSearchedCustomersHavingOutstanding(customers)
          );
          resolve();
        } catch (err) {
          console.log("searchCustomersHavingOutstandingBalance err : ", err);
          reject();
        }
      });
    };
  }

  static getAccount(accountId) {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        _CUSTOMERS_REF
          .doc(accountId)
          .get()
          .then(async (account) => {
            if (!account.metadata.fromCache) {
              // if data from cloud then return that data otherwise return redux data
              resolve(account.data());
            } else {
              let customer = await dispatch(
                this.getAccountFromRedux(accountId)
              );
              resolve(customer);
            }
          });
      });
    };
  }

  static sendRemindersToCustomers(customer, remindAll) {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        let { user } = getState();

        let customerIds = customer?.id || "";

        sendRemindersToCustomers(customerIds, user.id)
          .then(function (response) {
            console.log("response.data", response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error, "error");

            reject();
          });
      });
    };
  }

  static getAccountFromRedux(accountId) {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        let { accounts } = getState();
        let customers = accounts.accounts;
        console.log("customers : ", customers);
        let customer = customers.find((account) => account.id === accountId);
        resolve(customer);
      });
    };
  }

  static addAccount(data) {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        data = {
          ...data,
          from_new_app: false
        }
        _CUSTOMERS_REF
          .doc(data.id)
          .set(data)
          .then((res) => {
            console.log("Account has been created successfully in database!!!");
            resolve(res);
          })
          .catch((err) => {
            console.log("Account creation error");
            resolve(err);
          });
      });
    };
  }

  static addFieldToFirebaseDB() {
    var batch = firebase.firestore().batch();

    ACCOUNTS_REF.get().then((querySnapshot) => {
      querySnapshot.forEach(function (doc) {
        console.log("doc : ", doc);
        batch.update(doc.ref, { type: "default" });
      });
      batch.commit().then(function () {
        console.log("Batch Write Success !!");
      });
    });
  }

  static updateCustomerInRedux(accountId, updatedData) {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        console.log("updating customer in redux !!!");
        updatedData.customer_id = accountId;
        dispatch(AccountAction.updateCustomer(updatedData));
        dispatch(AccountAction.updateCustomerInAllCustomers(updatedData));
        resolve();
      });
    };
  }

  static update(accountId, updatedData) {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        updatedData = {
          ...updatedData,
          from_new_app: false
        }
        console.log("update account middleware");

        var docRef = _CUSTOMERS_REF.doc(accountId);

        docRef
          .update(updatedData)
          .then((res) => {
            console.log("customer updated in cloud db!");
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });

      });
    };
  }

  static updateImgUrlOfAccount(user_id, cdts_id, img_url) {
    return new Promise(async (resolve, reject) => {
      let responseFromFirebase = false;
      console.log("user_id : ", user_id, " cdts_id : ", cdts_id);
      let querySnapshot = await _CUSTOMERS_REF
        .where("user_id", "==", user_id)
        .where("creation_timestamp", "==", new Date(cdts_id))
        .get();
      console.log("querySnapshot : ", querySnapshot);
      if (querySnapshot.docs.length) {
        NetInfo.fetch().then((state) => {
          if (state.isConnected && state.isInternetReachable) {
            setTimeout(() => {
              if (!responseFromFirebase) {
                resolve();
              }
            }, 3000);
            _CUSTOMERS_REF
              .doc(querySnapshot.docs[0].id)
              .update({ img_url })
              .then((res) => {
                responseFromFirebase = true;
                resolve(res);
              })
              .catch((err) => {
                responseFromFirebase = true;
                reject(err);
              });
          } else {
            _CUSTOMERS_REF.doc(querySnapshot.docs[0].id).update({ img_url });
            resolve();
          }
        });
      } else resolve();
    });
  }

  static search(input) {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        console.log("searching locally !!");
        let { allAccounts } = getState().accounts;
        let filteredAccounts = [];
        filteredAccounts = allAccounts.filter((filteredAccount) =>
          filteredAccount.nameLowerCase.includes(input)
        );
        filteredAccounts = [...filteredAccounts].splice(0, 10);
        dispatch(AccountAction.getFilteredAccounts(filteredAccounts));
        resolve(filteredAccounts);
      });
    };
  }

  static resetLastVisible() {
    return (dispatch, getState) => {
      dispatch(AccountAction.resetLastVisibleAccount());
    };
  }
}
