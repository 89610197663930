export const USER = {
    LOGIN_USER: "LOGIN_USER",
    LOGOUT_USER: "LOGOUT_USER",
    UPDATE_USER: "UPDATE_USER",
};

export const SUGGESTIVE_WORDS = {
    LOAD_SUGGESTIVE_WORDS: "LOAD_SUGGESTIVE_WORDS",

};


export const ACCOUNT = {
    GET_ACCOUNTS: "GET_ACCOUNTS",
    GET_ALL_ACCOUNTS: "GET_ALL_ACCOUNTS",
    GET_CUSTOMERS_HAVING_OUTSTANDING: "GET_CUSTOMERS_HAVING_OUTSTANDING",
    GET_SEARCHED_CUSTOMERS_HAVING_OUTSTANDING: "GET_SEARCHED_CUSTOMERS_HAVING_OUTSTANDING",
    GET_FILTERED_CUSTOMERS_HAVING_OUTSTANDING: "GET_FILTERED_CUSTOMERS_HAVING_OUTSTANDING",
    GET_FILTERED_ACCOUNTS: "GET_FILTERED_ACCOUNTS",
    UPDATE_ACCOUNT: "UPDATE_ACCOUNT",
    UPDATE_ACCOUNT_IN_ALL_ACCOUNTS: "UPDATE_ACCOUNT_IN_ALL_ACCOUNTS",
    DELETE_ACCOUNT: "DELETE_ACCOUNT",
    ADD_ACCOUNT: "ADD_ACCOUNT",
    ADD_ACCOUNT_IN_ALL_ACCOUNTS: "ADD_ACCOUNT_IN_ALL_ACCOUNTS",
    GET_LAST_VISIBLE: "GET_LAST_VISIBLE",
    RESET_LAST_VISIBLE: "RESET_LAST_VISIBLE"

};

export const NOTIFICATION = {
    GET_CUSTOMERS_FOR_NOTIFICATION_FOR_TODAY: "GET_CUSTOMERS_FOR_NOTIFICATION_FOR_TODAY",
    GET_SCHEDULED_CUSTOMERS_FOR_NOTIFICATION: "GET_SCHEDULED_CUSTOMERS_FOR_NOTIFICATION",
    GET_PENDING_CUSTOMERS_FOR_NOTIFICATION: "GET_PENDING_CUSTOMERS_FOR_NOTIFICATION",

}

export const TRANSACTION = {
    GET_TRANSACTIONS: "GET_TRANSACTIONS",
    UPDATE_TRANSACTION: "UPDATE_TRANSACTION",
    DELETE_TRANSACTION: "DELETE_TRANSACTION",
    ADD_TRANSACTION: "ADD_TRANSACTION"
};


export const PHONE_CONTACTS = {
    GET_PHONE_CONTACTS: "GET_PHONE_CONTACTS",
    GET_FILTERED_PHONE_CONTACTS: "GET_FILTERED_PHONE_CONTACTS"
}

export const USER_STATEMENT = {
    GET_USER_TRANSACTION: "GET_USER_TRANSACTION"

}

export const ONBOARDING_STATES = {
    PRESSED_ADD_NEW_CUSTOMER: "PRESSED_ADD_NEW_CUSTOMER",
    PRESSED_CREATE_NEW_CUSTOMER: "PRESSED_CREATE_NEW_CUSTOMER",
    PRESSED_FIRST_CUSTOMER: "PRESSED_FIRST_CUSTOMER",
    PRESSED_HELP_BUTTON: "PRESSED_HELP_BUTTON",
    PRESSED_BACK_BUTTON: "PRESSED_BACK_BUTTON",
    MAKE_FIRST_TRANSACTION: "MAKE_FIRST_TRANSACTION",
    RESET_ALL: "RESET_ALL",
    ENABLE_ALL: "ENABLE_ALL"

}

export const ADS = {
    ADD_AD: "ADD_AD",
    REMOVE_AD: "REMOVE_AD",
    UPDATE_AD: "UPDATE_AD",
    GET_ADS: "GET_ADS"

}


export const REFERRALS = {
    GET_REFERRALS: "GET_REFERRALS"

}

export const LAYOUT = {
 
 
    CURRENT_TRANSACTION_DISPLAYED : "CURRENT_TRANSACTION_DISPLAYED",
    CURRENT_TRANSACTION_HIDDEN : "CURRENT_TRANSACTION_HIDDEN",
    CURRENT_STATEMENT_DISPLAYED : "CURRENT_STATEMENT_DISPLAYED",
    CURRENT_STATEMENT_HIDDEN : "CURRENT_STATEMENT_HIDDEN",
    OPEN_DRAWER :"OPEN_DRAWER",
    HIDE_DRAWER :"HIDE_DRAWER",
      

}