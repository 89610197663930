import React, { useEffect, useState } from "react";
import {
  ButtonBase,
  Grid,
  Icon,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import useStyles from "./styles";
import OnBoardingFooter from "../../common/OnBoardingFooter";
import LanguageUpdated from "../../../assets/language-updated.png";
import { useDispatch, useSelector } from "react-redux";
import { getIsAuthenticated, getLoggedInUser } from "../../../store/selectors";
import { auth } from "../../../constants";
import UserMiddleware from "../../../store/middleware/User";
import { changelocale, strings } from "../../../i18n";

export const Loading = ({ history }) => {
  const classes = useStyles();
  let unsubscribe;

  useEffect(() => {
    const language = localStorage.getItem("language");
 
    changelocale(language, true);
  }, []); // execute on mount

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);

  const loggedInUser = useSelector(getLoggedInUser);
  useEffect(() => {
    unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        dispatch(UserMiddleware.getUser(user.uid));

      //  console.log( "Logged In User",loggedInUser)
        if (loggedInUser?.is_active) {
          //Redirect to Dashboard
          console.log("User Logged in and Active ");
          history.push("/dashboard");
        } else {
          // todo Redirect to Profile page
       //   console.log("User logged in But did not complete profile yet");
          history.push("/createProfile" ,{ isNewAccount :true});
        }
      } else {
        const lang = localStorage.getItem("language");
        if (lang) {
         // console.log("Language already selected.....");
          history.push("/login");
        } else {
        //  console.log("navigate to welcome");
          history.push("/welcome");
        }
      }
      unsubscribe && unsubscribe();
    });
  }, []);

  return (
    <>
      <Grid
        style={{ minHeight: "100vh" }}
        container
        justify="center"
        direction="column"
        alignItems="center"
      >
        <CircularProgress className={classes.loader} />
        <Typography className={classes.loadingText}>
          {strings("YOUR_DATA_IS_100_SAFE_AND_SECURE")}
        </Typography>
      </Grid>
    </>
  );
};

export default Loading;
