import { LAYOUT } from "../actions/types";
import INITIAL_STATE from "./initialState";

export const View = {
  Customers: 0,
  CurrentTransaction: 1,
  CurrentStatement :2,  
};
const viewDisplayed = (state, view) => {
  return { ...state, views: [...state.views, view] };
};

const viewHidden = (state, view) => {
  return { ...state, views: state.views.filter((item) => item !== view) };
};

export var layoutReducer = (state = INITIAL_STATE.layout, action) => {
  switch (action.type) {
    case LAYOUT.CURRENT_TRANSACTION_DISPLAYED:
      return viewDisplayed(state, View.CurrentTransaction);

    case LAYOUT.CURRENT_TRANSACTION_HIDDEN:
      return viewHidden(state, View.CurrentTransaction);


    case LAYOUT.CURRENT_STATEMENT_DISPLAYED :
      return  viewDisplayed(state, View.CurrentStatement);
      
    case LAYOUT.CURRENT_STATEMENT_HIDDEN :
      return  viewHidden(state, View.CurrentStatement);
    case LAYOUT.OPEN_DRAWER:
      return {
        ...state,
        isDrawerOpen: true,
      };

    case LAYOUT.HIDE_DRAWER:
      return {
        ...state,
        isDrawerOpen: false,
      };

      
    default:
      return state;
  }
};
