import { makeStyles } from '@material-ui/core/styles';
import { themeStyleSheet, themeColors } from "../../../constants";

const useStyles = makeStyles({
    footerStyle: {
        backgroundColor: '#F2F2F2',
        fontSize: "20px",
        position: "fixed",
        left: 0,
        bottom: 0,
        height: "10vh",
  
        ["@media only screen and (min-device-width: 200px) and (max-device-width: 320px)  "]: {
            backgroundColor:  themeColors.whiteColor,
            padding: "0px",
            justifyContent:"center",
        },
        width: "100%"
    },
    phantomStyle: {
        display: "block",
        height: "10vh",
        width: "100%"
    },
    leftButtonCont:{
        ["@media only screen and (min-device-width: 200px) and (max-device-width: 320px)  "]: {
            display:"none"      
        }
    },
    leftButton: {
        backgroundColor: "rgba(0,0,0,0.03)",
  
    },
    rightButton: {
  
        ["@media only screen and (min-device-width: 200px) and (max-device-width: 320px)  "]: {
            backgroundColor:"#075E55",
            color:themeColors.whiteColor,
            height:"8vh",
            padding:"0px 10px",
            fontSize: "14px",

        }
    }
});

export default useStyles;
