import { memo } from "react";
import { CircularProgress } from "@material-ui/core";
import useStyles from "./styles";

export const Loader = memo(({ loading }) => {
  const styles = useStyles();

  if (loading)
    return (
      <div className={styles.parentDisable}>
        <div className={styles.overlayBox}>
          <CircularProgress color="secondary" />
        </div>
      </div>
    );

  return null;
});

export default Loader;
